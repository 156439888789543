import {
  AddAllAndNumber,
  ArrayToUnion,
  ISODate,
  KeyAndName,
  ObjectToUnion,
} from "../../../types/sharedTypes"
import { CustomButton, Settings, SettingsResponse } from "../../settings/types"
import { PaginatedResponse } from "../../types"
import { DEVICE_TYPES } from "./constants"
import { AVAILABLE_FACETS } from "./constants"

export enum ConnectivityInterface {
  ETHERNET = "Ethernet",
  WIFI = "Wifi",
  MOBILE = "Mobile",
}

export enum ConnectivityType {
  NONE = 0,
  RS9110_WIFI = 1,
  SIM = 2,
  W5500_ETHERNET = 3,
  REDPINE_RS9113 = 4,
  ATMEL_WINC1500 = 5,
  CC3100_WIFI = 6,
  MAC_ETHERNET = 7,
  SIM7500_MOBILE = 8,
  RS9116_WIFI = 9,
  CC3135_WIFI = 10,
}

export type RoomDeviceResponse = {
  id: string
  name: string
  interface: number
  gtin: string
  type: DeviceType
  rooms: KeyAndName[]
  wifi_quality: number | null
  battery: number
  license: {
    subscription_id: number
    assigned_at: ISODate | null
    expires_at: ISODate | null
  }
  provider: string | null
  description: string | null
  status_sent_at: ISODate | null
  connectivity_interface: ConnectivityInterface | null
  connectivity_type: ConnectivityType | null
} & SettingsResponse

export type RoomsDevicesResponse = PaginatedResponse<RoomDeviceResponse>

export type RoomDevicePairResponse = {
  success: number
  id: string
  device: {
    authenticated: string
    uuid: string
    calendar: string | null
    calendar_name: string | null
    user: number
    gtin: string
    timezone: string | null
    wifi: number
    battery: number
    model: number
    size: number
    featureset: []
    locations: []
    calendars: []
    roomResources: []
    room_resources: []
    customButtons: CustomButton[]
    interface: number
    provider: "OPTOMA" | null
    status_sent_at: ISODate
    license: {
      subscription_id: number
      assigned_at: ISODate | null
      expires_at: ISODate | null
    }
    wifi_quality: number | null
  }
}

export type RoomsDevicesFetchRequest = {
  building?: string
  floor?: string
  type?: string
  battery?: string
  connectivity?: string
  search?: string
  facets?: DevicesFacetQuery[]
}

export type RoomDeviceUpdateRequest = {
  name?: string
  rooms?: string[]
  interface?: number
  settings?: Partial<Settings>
  description?: string
}

export type RoomDeviceRequestWithId = RoomDeviceUpdateRequest & {
  id: string
}

export type DevicesFacets = ArrayToUnion<typeof AVAILABLE_FACETS>

export type DevicesFacetQuery = AddAllAndNumber<DevicesFacets>

export type DeviceType = ObjectToUnion<typeof DEVICE_TYPES>
