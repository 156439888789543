import React from "react"

import { useTranslation } from "react-i18next"

import DeviceSettingsForm from "./DeviceSettingsForm"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const DeviceSettings = () => {
  const { t } = useTranslation()

  return (
    <View className="RoomsDeviceSettings">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.device_settings.title"),
        ]}
      />

      <Intro isConstrained>
        <div className="description">
          {t("desktop.settings.rooms.device_settings.intro")}
        </div>
      </Intro>

      <Space size={0.75} />

      <DeviceSettingsForm />
    </View>
  )
}

export default DeviceSettings
