import React from "react"

import { useTranslation } from "react-i18next"

import MetabaseEmbed from "../../MetabaseEmbed"

import { useFetchDashboardQuery } from "../../../../redux/api/analytics"
import { AnalyticsDashboardVariant } from "../../../../redux/api/analytics/types"

import Loader from "../../../../components/basic/Loader"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./style.sass"

const DesksPage = () => {
  const { t } = useTranslation()

  const { data: { url: dashboardUrl } = {}, isFetching } =
    useFetchDashboardQuery({
      dashboard_id: AnalyticsDashboardVariant.DeskBooking,
    })

  return (
    <View className="Desks">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.analytics.title"),
          t("desktop.analytics.desk_and_asset_booking.title"),
          t("desktop.analytics.desk_and_asset_booking.dashboard.title"),
        ]}
      />

      <Intro>
        {t("desktop.analytics.desk_and_asset_booking.dashboard.subtitle")}
      </Intro>

      <Space size={0.75} />

      {isFetching ? <Loader /> : <MetabaseEmbed url={dashboardUrl} />}
    </View>
  )
}

export default DesksPage
