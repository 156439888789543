import React from "react"

import classNames from "classnames"
import { useTranslation } from "react-i18next"

import "./NoDataFound.sass"

type NoDataFoundProps = {
  block?: boolean
  children?: React.ReactNode
  warning?: boolean
  className?: string
}

const NoDataFound = ({
  children,
  warning = false,
  className,
}: NoDataFoundProps): JSX.Element => {
  const { t } = useTranslation()
  const message = t("general.not_found.no_data_found")

  const noDataFoundClasses = classNames(
    "NoDataFound",
    {
      default: !warning,
      warning: warning,
    },
    className,
  )

  return (
    <div className={noDataFoundClasses}>
      <span>{children || message}</span>
    </div>
  )
}

export default NoDataFound
