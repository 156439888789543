import React from "react"

import { useTranslation } from "react-i18next"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./style.sass"

const Layout = () => {
  const { t } = useTranslation()

  return (
    <View className="SettingsRoomsLayout">
      <Breadcrumbs
        depth={2}
        includeParamsAsPath
        values={[
          t("desktop.settings.rooms.title"),
          t("desktop.settings.rooms.layout.title"),
        ]}
      />

      <Intro>{t("desktop.settings.rooms.layout.intro")}</Intro>

      <Space size={0.75} />
    </View>
  )
}

export default Layout
