import React, { useState } from "react"

import cn from "classnames"

import { isValidURL } from "../../utils"

import Loader from "../../components/basic/Loader"

import "./MetabaseEmbed.sass"

const MetabaseEmbed = ({
  url,
  width = "100%",
  height = "75vh",
}: {
  url?: string
  width?: string
  height?: string
}) => {
  const [isLoading, setLoading] = useState(true)

  const handleLoad = () => {
    setLoading(false)
  }

  return (
    <div className={cn("MetabaseEmbed", { isLoading })}>
      {isValidURL(url) ? (
        <>
          {isLoading && <Loader />}

          <iframe
            className="MetabaseEmbedIframe"
            src={url}
            style={{ width, height }}
            allow="fullscreen"
            onLoad={handleLoad}
          />
        </>
      ) : (
        <div>Viewing embedded analytics is not supported at the moment.</div>
      )}
    </div>
  )
}

export default MetabaseEmbed
