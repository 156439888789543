import { ISODate } from "../../../types/sharedTypes"

export enum AnalyticsDashboardVariant {
  DeskBooking = 2,
  RoomBooking = 3,
  VisitorManagement = 5,
}

export type AnalyticsDashboard = {
  url: string
}

export type AnalyticsDashboardResponse = AnalyticsDashboard

export type AnalyticsDashboardOptions = {
  dashboard_id: AnalyticsDashboardVariant
  start?: ISODate
  end?: ISODate
  user_emails?: string[]
  building_ids?: string[]
}
