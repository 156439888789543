import React from "react"

import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"

import Breadcrumbs from "../../../components/Breadcrumbs"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./EditRoomReservation.sass"

type ParamsType = {
  id: string
}

const EditRoomReservation = () => {
  const { t } = useTranslation()

  const { id } = useParams<ParamsType>()

  return (
    <View className="EditRoomReservation">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.manage.room_booking.title"),
          id
            ? t("desktop.manage.room_booking.form.edit_reservation")
            : t("desktop.manage.room_booking.form.new_reservation"),
        ]}
      />

      <Space size={0.75} />
    </View>
  )
}

export default EditRoomReservation
