import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { assetsGetURL, usersURL } from "../../api"
import { ReservationCheckinUtils } from "../../checkin_utils"
import { FEATURE_FLAGS, PERMISSIONS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { useCheckForPermission } from "../../hooks/useCheckForPermission"
import useCheckReservationWindowLength from "../../hooks/useCheckReservationWindowLength"
import { useFromToCalculator } from "../../hooks/useFromToCalculator"
import { useToast } from "../../hooks/useToast"
import DeleteReservationModal from "../../modals/DeleteReservationModal"
import FailedReservationsModal from "../../modals/FailedReservationsModal"
import UpdateReservationModal from "../../modals/UpdateReservationModal"
import {
  InternalTime,
  OptionType,
  RecurringType,
} from "../../types/sharedTypes"
import { setTimeToDayjs } from "../../utils"
import AsyncSelect from "../advanced/AsyncSelect"
import Button from "../advanced/Button"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import { DatePicker } from "../advanced/DatePicker"
import Loader from "../basic/Loader"
import { Select } from "../basic/Select"
import { TimeRange, TimeRangePicker } from "../basic/TimeRangePicker"
import Field from "../Field"
import { CheckinSummary } from "../Manage/CheckinSummary"
import { timeZone as defaultTimezone } from "./../../dayjs"
import { setErrors } from "./formUtils"
import ModalForm from "./ModalFormHook"
import { getScheduleOptions, TIMEZONE_OPTIONS, timezoneMapper } from "./options"
import { useModals } from "@mattjennings/react-modal-stack"
import { skipToken } from "@reduxjs/toolkit/dist/query"

import { useFetchAssetQuery } from "../../redux/api/assets"
import { selectApp } from "../../redux/app/selectors"
import {
  checkinAssetReservation,
  checkoutAssetReservation,
  clearAssetReservationErrorState,
  createAssetReservation,
  deleteAssetReservation,
  fetchAssetReservation,
  updateAssetReservation,
} from "../../redux/asset_reservation/assetReservationSlice"
import { getAssetReservationSelector } from "../../redux/asset_reservation/selectors"
import {
  AssetReservation,
  CreateAssetReservation,
  isRecurringAssetReservation,
} from "../../redux/asset_reservation/types"
import { Asset } from "../../redux/assets/types"
import { useAppSelector } from "../../redux/reducers"
import { selectSettingsEffective } from "../../redux/settings/selectors"
import { selectUser } from "../../redux/user/selectors"
import {
  formatUser,
  isOfficeManager,
  isPortalAdmin,
} from "../../redux/user/utils"
import { UserResponse } from "../../redux/users/types"
import { useActions } from "../../redux/utils"

import "./AssetBookingForm.sass"

const FORM_MAPPING = {
  end: ["timeRange", "date"],
  start: ["timeRange", "date"],
  "recurring_rule.until": "scheduleDate",
  asset_id: "asset",
} as const

export type AssetFormType = {
  start: string
  end: string
  date: Dayjs
  schedule?: OptionType<string | null>
  asset?: string
  assetName?: string
  scheduleDate?: Dayjs
  timezone?: string | null
}
type FormValues = {
  user: UserResponse | null
  timeRange: Required<TimeRange>
  date: Date
  schedule?: OptionType<string | null>
  asset: Asset
  scheduleDate: Date
  timezone?: OptionType<string | null>
}

type Props = {
  reservationId?: string
  assetType: string
  assetFormData?: AssetFormType
  dataUpdate?: () => void
}

export const AssetBookingForm = ({
  reservationId,
  assetType,
  assetFormData,
  dataUpdate,
}: Props) => {
  const [isCheckSubmitting, setIsCheckSubmitting] = useState(false)

  const { openModal, closeModal } = useModals()
  const { errorToast, infoToast } = useToast()
  const history = useHistory()
  const { t } = useTranslation()

  const { entry: settings } = useAppSelector(selectSettingsEffective)

  const { entry: currentUser } = useAppSelector(selectUser)

  const desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: true,
  })

  const admin_desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: false,
  })

  const { showWeekends } = useAppSelector(selectApp)

  const { reservations, isLoading } = useAppSelector(
    getAssetReservationSelector,
  )

  const { data: reservationAsset, isSuccess: isAssetLoaded } =
    useFetchAssetQuery(assetFormData?.asset ?? skipToken)

  const isAdminManager =
    isOfficeManager(currentUser) || isPortalAdmin(currentUser)

  const reservation: AssetReservation | undefined =
    reservations[reservationId || ""]

  const reservationUser = reservation ? reservation.user : currentUser

  const ownReservation = reservation
    ? reservation.user.email === currentUser.email
    : true

  const canCreatePermission = useCheckForPermission(
    PERMISSIONS.assets.canAddAsset,
  )
  const canDeletePermission = useCheckForPermission(
    PERMISSIONS.assets.canDeleteAsset,
  )
  const canEditPermission = useCheckForPermission(
    PERMISSIONS.assets.canChangeAsset,
  )

  const isEditRecurringReservationsEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.EDIT_RECURRING_RESERVATIONS,
  )

  const checkoutEnabled = ReservationCheckinUtils.isEnabled(
    "checkout",
    settings,
    currentUser,
    reservation,
  )

  const adminMaxDate = dayjs().add(
    admin_desk_reservation_window_length ?? 0,
    "day",
  )
  const maxDate = dayjs().add(
    (isAdminManager
      ? admin_desk_reservation_window_length
      : desk_reservation_window_length) ?? 0,
    "day",
  )
  const canCreate = ownReservation || canCreatePermission
  const canDelete = ownReservation || canDeletePermission
  const canEdit = ownReservation || canEditPermission
  const formFieldEnabled = canEdit && !checkoutEnabled
  const { defaultFrom, defaultTo } = useFromToCalculator(reservation)

  /**
   * Memo
   */
  const calculateFormValue = useMemo(() => {
    const { start, end, date, schedule, asset, scheduleDate } =
      assetFormData || {}

    return {
      user: reservationUser,
      date: reservation
        ? dayjs(reservation.start).toDate()
        : (date?.toDate() ?? dayjs().toDate()),
      timeRange: {
        start: (start || defaultFrom) as InternalTime,
        end: (end || defaultTo) as InternalTime,
      },
      schedule: schedule ?? { value: null, label: t("general.repeat.once") },
      asset: reservationId
        ? reservation?.asset
        : asset
          ? reservationAsset
          : undefined,
      scheduleDate: !reservation
        ? (scheduleDate?.toDate() ?? maxDate.toDate())
        : maxDate.toDate(),

      timezone: reservation
        ? { value: reservation.tz, label: reservation.tz }
        : { value: defaultTimezone, label: defaultTimezone },
    }
  }, [
    assetFormData,
    reservationUser,
    reservation,
    defaultFrom,
    defaultTo,
    t,
    reservationId,
    maxDate,
    reservationAsset,
  ])

  const methods = useForm<FormValues>({
    defaultValues: calculateFormValue,
  })

  const {
    setError,
    control,
    formState: { isSubmitting },
    reset,
    watch,
    setValue,
    getValues,
  } = methods

  /**
   * Actions
   */
  const actions = useActions({
    fetchAssetSchedule: (id: string) => fetchAssetReservation(id),
    checkinAssetReservation: (id: string) => checkinAssetReservation(id),
    checkoutAssetReservation: (id: string) => checkoutAssetReservation(id),
    delete: (id: string) => deleteAssetReservation({ reservationId: id }),
    create: (reservation: CreateAssetReservation) =>
      createAssetReservation(reservation),
    update: (id: string, reservation: Partial<CreateAssetReservation>) =>
      updateAssetReservation({ reservationId: id, payload: reservation }),
    clearErrors: () => clearAssetReservationErrorState(null),
  })

  const asset = watch("asset")

  const onCloseClick = (e: MouseEvent) => {
    e?.preventDefault?.()
    history.push("/manage/assets")
  }

  const onCreateClick = async ({
    user,
    timeRange,
    date,
    schedule,
    asset,
    scheduleDate,
    timezone,
  }: FormValues) => {
    const isRecurring = schedule?.value && scheduleDate
    const createPayload: CreateAssetReservation = {
      user_email: user?.email ?? "",
      asset_id: asset?.id,
      tz: timezone?.value ?? defaultTimezone,
      start: date
        ? setTimeToDayjs(dayjs(date ?? ""), timeRange.start).toISOString()
        : undefined,
      end: date
        ? setTimeToDayjs(dayjs(date ?? ""), timeRange.end).toISOString()
        : undefined,
      recurring: isRecurring
        ? {
            freq: schedule.value as RecurringType,
            until: dayjs(scheduleDate ?? "")
              .endOf("day")
              .toISOString(),
          }
        : undefined,
    }
    const response = await actions.create(createPayload)
    if (createAssetReservation.fulfilled.match(response)) {
      infoToast(
        isRecurring
          ? t("desktop.manage.asset_booking.form.reservations_created_toast")
          : t("desktop.manage.asset_booking.form.reservation_created_toast"),
      )
      dataUpdate?.()

      // Special error handling for recurring reservation errors
      if (
        isRecurringAssetReservation(response.payload) &&
        response.payload.failed?.length > 0
      ) {
        openModal(FailedReservationsModal, {
          failedReservations: response.payload.failed,
          reservationType: "asset",
        })
        return
      }

      history.push("/manage/assets")
    } else if (createAssetReservation.rejected.match(response)) {
      if (response.payload) {
        setErrors(response.payload, setError, errorToast, FORM_MAPPING)
      }
    }
  }

  const onUpdateClick = async ({
    user,
    timeRange,
    date,
    asset,
    timezone,
  }: FormValues) => {
    const editPayload: Partial<CreateAssetReservation> = {
      user_email: user?.email ?? "",
      asset_id: asset.id,
      tz: timezone?.value ?? defaultTimezone,
      start: date
        ? setTimeToDayjs(dayjs(date), timeRange.start).toISOString()
        : undefined,
      end: date
        ? setTimeToDayjs(dayjs(date), timeRange.end).toISOString()
        : undefined,
    }

    if (isEditRecurringReservationsEnabled && reservation.recurring?.id) {
      openModal(UpdateReservationModal, {
        reservationId: reservation.id,
        reservationType: "asset",
        payload: editPayload,
        dataUpdate,
      })
    } else {
      if (reservationId) {
        const response = await actions.update(reservationId, editPayload)

        if (updateAssetReservation.fulfilled.match(response)) {
          infoToast(
            t("desktop.manage.asset_booking.form.reservation_updated_toast"),
          )
          dataUpdate?.()
          history.push("/manage/assets")
        } else if (updateAssetReservation.rejected.match(response)) {
          if (response.payload) {
            setErrors(response.payload, setError, errorToast, FORM_MAPPING)
          }
        }
      }
    }
  }

  const onDeleteClick = async (e: MouseEvent) => {
    e.preventDefault()
    if (reservation.recurring?.freq && reservation.recurring.until) {
      openModal(DeleteReservationModal, {
        reservationId: reservation.id,
        reservationType: "asset",
        dataUpdate,
      })
    } else {
      if (reservationId) {
        const response = await actions.delete(reservationId)
        if (deleteAssetReservation.fulfilled.match(response)) {
          infoToast(
            t("desktop.manage.asset_booking.form.reservation_deleted_toast"),
          )
          dataUpdate?.()
          history.push("/manage/assets")
        }
      }
    }
  }

  const onCheckInClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()
      if (reservationId && !isCheckSubmitting) {
        setIsCheckSubmitting(true)
        const response = await actions.checkinAssetReservation(reservationId)
        if (checkinAssetReservation.fulfilled.match(response)) {
          infoToast(t("desktop.manage.asset_booking.form.checked_in_toast"))
          dataUpdate?.()
          history.push("/manage/assets")
        } else {
          errorToast(response.error.message)
        }
        setIsCheckSubmitting(false)
      }
    },
    [
      actions,
      dataUpdate,
      errorToast,
      history,
      infoToast,
      reservationId,
      t,
      isCheckSubmitting,
    ],
  )

  const handleCheckOutConfirmation = async (e: MouseEvent) => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await onCheckOutClick(e)
        closeModal()
      },
    })
  }

  const onCheckOutClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()
      if (reservationId) {
        setIsCheckSubmitting(true)
        const response = await actions.checkoutAssetReservation(reservationId)
        if (checkoutAssetReservation.fulfilled.match(response)) {
          infoToast(t("desktop.manage.asset_booking.form.checked_out_toast"))
          dataUpdate?.()
          history.push("/manage/assets")
        } else {
          errorToast(response.error.message)
        }
        setIsCheckSubmitting(false)
      }
    },
    [actions, dataUpdate, history, infoToast, errorToast, reservationId, t],
  )

  const datePickerOnChange = (
    value: Date | null,
    onChange: (...event: any[]) => void,
  ) => {
    onChange(value)
    if (
      value &&
      getValues().schedule?.value === RecurringType.EVERY_DAY_OF_WEEK
    ) {
      setValue("schedule", {
        label: t("general.repeat.every_day_of_week", {
          day: dayjs(value).format("dddd"),
        }),
        value: RecurringType.EVERY_DAY_OF_WEEK,
      })
    }
  }

  /**
   * Effects
   */
  useEffect(() => {
    if (!asset || (asset?.id === reservation?.asset?.id && reservation?.tz)) {
      return
    }
    setValue("timezone", {
      value: asset?.tz ?? "UTC",
      label: timezoneMapper(asset?.tz ?? "UTC"),
    })
  }, [asset, setValue, reservation])

  useEffect(() => {
    if (reservationId) {
      actions.fetchAssetSchedule(reservationId)
    }
  }, [actions, reservationId])

  useEffect(() => {
    reset(calculateFormValue)
  }, [reservation, isAssetLoaded])

  useEffect(() => {
    // Cleanup for errors on unmount
    return () => {
      actions.clearErrors()
    }
  }, [actions])

  const showForm = reservationId ? reservation : true

  const isFormDisabled = reservation ? !canEdit : !canCreate

  const getAdditionalButton = () => {
    if (
      ReservationCheckinUtils.isEnabled(
        "checkin",
        settings,
        currentUser,
        reservation,
      )
    ) {
      return (
        <Button
          className="checkin"
          onClick={onCheckInClick}
          isDisabled={isLoading || isSubmitting || isCheckSubmitting}
          variant="secondary"
        >
          {t("desktop.manage.asset_booking.form.check_in")}
        </Button>
      )
    }

    if (checkoutEnabled) {
      return (
        <Button
          variant="danger-pop"
          className="checkout"
          noConfirm
          onClick={handleCheckOutConfirmation}
          isDisabled={isLoading || isSubmitting || isCheckSubmitting}
        >
          {t("desktop.manage.asset_booking.form.check_out")}
        </Button>
      )
    }
  }
  /**
   * Reservations are loaded but none was found.
   */
  if (!isLoading && !reservation && reservationId) {
    return (
      <div className="BookingForm NotFound">
        {t("general.reservation.not_exist")}
      </div>
    )
  }

  return showForm ? (
    <FormProvider {...methods}>
      <ModalForm
        className="BookingForm"
        updateMode={checkoutEnabled ? false : !!reservation}
        title={
          !reservation
            ? t("desktop.manage.asset_booking.form.new_reservation")
            : canEdit
              ? t("desktop.manage.asset_booking.form.edit_reservation")
              : t("desktop.manage.asset_booking.form.view_reservation")
        }
        onCreate={!checkoutEnabled ? onCreateClick : undefined}
        onUpdate={!checkoutEnabled ? onUpdateClick : undefined}
        onDelete={
          !!reservation && canDelete && formFieldEnabled
            ? onDeleteClick
            : undefined
        }
        hasConfirmationPrompt={
          reservation &&
          reservation?.recurring?.freq &&
          reservation?.recurring?.until
            ? false
            : true
        }
        onClose={onCloseClick}
        additionalButton={getAdditionalButton()}
        disabled={isFormDisabled}
      >
        <div className="field-width-50">
          <Field
            control={control}
            name="user"
            label={t("desktop.manage.asset_booking.form.person")}
          >
            {(props) => (
              <AsyncSelect
                {...props}
                urlGenerator={(fetchOptions) => {
                  return usersURL(fetchOptions)
                }}
                getOptionLabel={(user) => formatUser(user ?? {})}
                getOptionValue={(user) => user?.email ?? ""}
                disabled={
                  isSubmitting ||
                  checkoutEnabled ||
                  !(isOfficeManager(currentUser) || isPortalAdmin(currentUser))
                }
                nothingFoundMessage={t("general.not_found.no_options_found")}
              />
            )}
          </Field>

          <Field
            control={control}
            name="date"
            label={t("desktop.manage.asset_booking.form.date")}
          >
            {({ onChange, ...props }) => (
              <DatePicker
                {...props}
                maxDate={adminMaxDate.toDate()}
                disabled={isSubmitting}
                onChange={(v: Date | null) => datePickerOnChange(v, onChange)}
              />
            )}
          </Field>

          {!reservation && (
            <Field
              control={control}
              name="schedule"
              label={t("desktop.manage.asset_booking.form.schedule")}
            >
              {(props) => (
                <Select
                  {...props}
                  options={getScheduleOptions(
                    // @ts-ignore https://github.com/orgs/react-hook-form/discussions/7764
                    dayjs(watch("date") ?? "").format("dddd"),
                    showWeekends,
                  )}
                  disabled={isSubmitting}
                  nothingFoundMessage={t("general.not_found.no_options_found")}
                />
              )}
            </Field>
          )}
        </div>

        <div className="field-width-50">
          <Field
            control={control}
            name="asset"
            label={t("desktop.manage.asset_booking.form.asset")}
          >
            {(props) => (
              <AsyncSelect
                {...props}
                urlGenerator={(fetchOptions) => {
                  return assetsGetURL({
                    ...fetchOptions,
                    asset_type: assetType,
                  })
                }}
                getOptionLabel={(asset) => asset.name}
                getOptionValue={(asset) => asset.id}
                disabled={isSubmitting}
                filterResultsFn={(asset) => asset.active !== false}
                nothingFoundMessage={t("general.not_found.no_options_found")}
              />
            )}
          </Field>

          <Field
            control={control}
            name="timeRange"
            label={t("desktop.manage.asset_booking.form.time")}
          >
            {(props) => <TimeRangePicker {...props} disabled={isSubmitting} />}
          </Field>

          {watch("schedule")?.value !== null && (
            <Field
              control={control}
              name="scheduleDate"
              label={t("desktop.manage.asset_booking.form.until_date")}
            >
              {(props) => (
                <DatePicker
                  {...props}
                  maxDate={maxDate.toDate()}
                  value={props.value || maxDate.toDate()}
                  disabled={isSubmitting}
                />
              )}
            </Field>
          )}

          <Field
            control={control}
            name="timezone"
            label={t("desktop.manage.asset_booking.form.timezone")}
            subText={t("general.optional")}
          >
            {(props) => (
              <Select
                {...props}
                options={TIMEZONE_OPTIONS}
                disabled={isSubmitting}
                clearable
                nothingFoundMessage={t("general.not_found.no_options_found")}
              />
            )}
          </Field>
        </div>

        <CheckinSummary
          checkin_at={reservation?.checked_in?.check_in_at}
          checkout_at={reservation?.checked_in?.check_out_at}
        />
      </ModalForm>
    </FormProvider>
  ) : (
    <Loader />
  )
}
