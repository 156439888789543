import { api } from ".."
import { analyticsDashboardURL } from "../../../api"
import { AnalyticsDashboardOptions, AnalyticsDashboardResponse } from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

export const analytics = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchDashboard: builder.query<
      AnalyticsDashboardResponse,
      AnalyticsDashboardOptions
    >({
      query: ({ building_ids, user_emails, ...options }) => {
        const isAllBuildingSelected = building_ids?.includes(
          FilterSpecialValues.ALL,
        )
        const isAllEmailsSelected = user_emails?.includes(
          FilterSpecialValues.ALL,
        )

        return {
          url: analyticsDashboardURL(),
          method: "POST",
          body: {
            ...options,
            building_ids: isAllBuildingSelected ? [] : building_ids,
            user_emails: isAllEmailsSelected ? [] : user_emails,
          },
        }
      },
      providesTags: ["AnalyticsDashboard"],
      keepUnusedDataFor: 0,
    }),
  }),
})

export const { useFetchDashboardQuery } = analytics
