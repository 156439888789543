import React, { MouseEvent, useState } from "react"

import { t } from "i18next"
import { useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import Button from "../advanced/Button"
import Options from "../basic/Options"
import { Field } from "../Field/Field"
import { toast } from "../Toast"
import { useModals } from "@mattjennings/react-modal-stack"

import { useUpdateDeskReservationMutation } from "../../redux/api/deskReservations"
import { CreateDeskReservation } from "../../redux/api/deskReservations/types"
import { isRejected } from "../../redux/api/types"
import { updateAssetReservation } from "../../redux/asset_reservation/assetReservationSlice"
import { CreateAssetReservation } from "../../redux/asset_reservation/types"
import {
  ReservationActionType,
  ReservationType,
} from "../../redux/reservations/types"
import { useActions } from "../../redux/utils"

import CrossSVG from "../../assets/images/icons/Cross.svg"

import "./UpdateReservationForm.sass"

type Props = {
  reservationId: string
  dataUpdate?: () => void
  reservationType: ReservationType
  payload: Partial<CreateAssetReservation> | Partial<CreateDeskReservation>
}

const reservationTypeOptions = [
  {
    value: ReservationActionType.CURRENT,
    label: t("general.reservation.this_reservation"),
  },
  {
    value: ReservationActionType.CURRENT_FUTURE,
    label: t("general.reservation.this_and_following_reservations"),
  },
]

export const UpdateReservationForm = ({
  reservationId,
  dataUpdate,
  reservationType,
  payload,
}: Props) => {
  const { closeModal } = useModals()
  const { t } = useTranslation()
  const [typeOption, setTypeOption] = useState<ReservationActionType>(
    ReservationActionType.CURRENT,
  )
  const [updatedDeskReservation] = useUpdateDeskReservationMutation()

  const actions = useActions({
    updateAssetReservation: (
      id: string,
      type: ReservationActionType,
      reservation: Partial<CreateAssetReservation>,
    ) =>
      updateAssetReservation({ reservationId: id, type, payload: reservation }),
  })

  const onUpdateClick = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    let response

    if (reservationType === "desk") {
      response = await updatedDeskReservation({
        id: reservationId,
        type: typeOption,
        payload,
      })
    } else if (reservationType === "asset") {
      response = await actions.updateAssetReservation(
        reservationId,
        typeOption,
        payload,
      )
    }

    if (response) {
      if (!isRejected(response)) {
        if (typeOption === ReservationActionType.CURRENT) {
          toast.info(
            t("desktop.manage.asset_booking.form.reservation_updated_toast"),
            { hideProgressBar: true },
          )
        } else {
          toast.info(
            t("desktop.manage.desk_booking.form.reservation_updated_toast"),
            { hideProgressBar: true },
          )
        }
        analyticsEvent(SupportedEvents.RESERVATION_UPDATE, {
          id: reservationId,
          type: typeOption,
        })
        dataUpdate?.()
      } else {
        toast.error(response.error.message, { hideProgressBar: true })
      }
    }
  }

  return (
    <div className="ModalForm UpdateReservationForm">
      <div className="title">
        <h1>{t("desktop.manage.desk_booking.form.edit_recurring")}</h1>
      </div>
      <div className="close" onClick={() => closeModal()}>
        <CrossSVG />
      </div>

      <Field>
        <Options
          options={reservationTypeOptions}
          value={typeOption}
          onChange={setTypeOption}
        />
      </Field>

      <div className="actions actions-save-only">
        <Button noConfirm onClick={onUpdateClick}>
          {t("general.save")}
        </Button>
      </div>
    </div>
  )
}

export default UpdateReservationForm
