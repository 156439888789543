import React, { useEffect, useState } from "react"

import { Trans, useTranslation } from "react-i18next"

import { FEATURE_FLAGS } from "../../../../constants"
import { useCheckForFeatureFlag } from "../../../../hooks/useCheckForFeatureFlag"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { BILLING_PATHS } from "../../Billing/constants"

import {
  useFetchProDvxIntegrationQuery,
  useUpdateProDvxIntegrationMutation,
} from "../../../../redux/api/prodvx"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import Button from "../../../../components/advanced/Button"
import { RedirectLink } from "../../../../components/advanced/RedirectLink"
import Card from "../../../../components/basic/Card"
import { Input } from "../../../../components/basic/Input"
import Switch from "../../../../components/basic/Switch"
import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./OtherDevices.sass"

const OtherDevices = () => {
  const { push } = useNavigation()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const { data: proDvxIntegration } = useFetchProDvxIntegrationQuery()
  const [updateProDvxIntegration] = useUpdateProDvxIntegrationMutation()

  const hasProDvxFeature = useCheckForFeatureFlag(FEATURE_FLAGS.PRODVX_ENABLED)

  const [isProDvxEnabled, setIsProDvxEnabled] = useState(false)
  const [proDvxAccessToken, setProDvxAccessToken] = useState<
    string | undefined
  >()

  const handleSaveClick = async () => {
    if (!proDvxAccessToken) {
      errorToast(t("desktop.settings.integrations.api.token_required"))
      return
    }

    const response = await updateProDvxIntegration({
      enabled: isProDvxEnabled,
      access_token: proDvxAccessToken,
    })

    if (isRejected(response)) {
      const { error } = response
      if (isApiResponseError(error)) {
        errorToast(error.message)
        return
      }
    }

    infoToast(t("desktop.settings.integrations.api.token_saved"))
  }

  const handleToggleChange = async (newValue: boolean) => {
    setIsProDvxEnabled(newValue)

    if (!newValue) {
      const response = await updateProDvxIntegration({
        enabled: false,
        access_token: proDvxAccessToken || "_",
      })

      if (isRejected(response)) {
        const { error } = response
        if (isApiResponseError(error)) {
          errorToast(error.message)
          return
        }
      }
    }
  }

  useEffect(() => {
    if (proDvxIntegration) {
      setIsProDvxEnabled(proDvxIntegration.enabled)
      setProDvxAccessToken(proDvxIntegration.access_token)
    }
  }, [proDvxIntegration])

  return (
    <View className="OtherDevices">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.integrations.title"),
          t("desktop.settings.integrations.other_devices.title"),
        ]}
      />

      <Intro isConstrained>
        <Trans i18nKey="desktop.settings.integrations.other_devices.intro" />
      </Intro>

      <Space size={0.75} />

      <Card className="OtherDevicesToggleCard">
        <div className="ToggleContainer">
          <Switch
            value={isProDvxEnabled}
            onChange={handleToggleChange}
            label={t(
              "desktop.settings.integrations.other_devices.prodvx.title",
            )}
          />
        </div>

        <div className="TokenContainer">
          <span className="TokenTitle">
            {t("desktop.settings.integrations.other_devices.prodvx.token")}
          </span>
          <span className="TokenDescription">
            <Trans
              i18nKey="desktop.settings.integrations.other_devices.prodvx.token_description"
              components={{
                a: <RedirectLink to="prodvx-kb" />,
              }}
            ></Trans>
          </span>

          {isProDvxEnabled && hasProDvxFeature && (
            <div className="ExistingTokenContainer">
              <Input
                value={proDvxAccessToken}
                onChange={setProDvxAccessToken}
                placeholder={t(
                  "desktop.settings.integrations.api.token_placeholder",
                )}
              />
              <Button
                className="RegenerateTokenButton"
                onClick={handleSaveClick}
                noConfirm
              >
                {t("general.save")}
              </Button>
            </div>
          )}
        </div>

        {!hasProDvxFeature && (
          <div className="OtherDevicesFeatureDisabled">
            <span className="NotAvailable">
              {t(
                "desktop.settings.integrations.other_devices.prodvx.feature_disabled",
              )}
            </span>
            <Button
              onClick={() => push(BILLING_PATHS.overview.root)}
              variant="link"
            >
              {t("desktop.settings.integrations.scim.upgrade_cta")}
            </Button>
          </div>
        )}
      </Card>
    </View>
  )
}

export default OtherDevices
