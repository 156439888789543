import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"

import { ENTRIES_PER_PAGE_KEY } from "../../../constants"
import { timeZone } from "../../../dayjs"
import { useBackendPagination } from "../../../hooks/useBackendPagination"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useFetchPaginatedData } from "../../../hooks/useFetchPaginatedData"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import AssetBookingModal from "../../../modals/AssetBookingModal"
import { parseQueryWithDefault, updateHistory } from "../../../utils"
import {
  ASSETS_FILTER_STORE_NAME,
  ASSETS_SCHEDULE_PATHNAME,
  ENTRIES_PER_PAGE,
} from "../constants"
import AssetFilters, { Filter } from "./Filters"
import Header from "./Header"
import { useModals } from "@mattjennings/react-modal-stack"

import { useFetchAssetsQuery } from "../../../redux/api/assets"
import { useFetchAssetTypeQuery } from "../../../redux/api/assetTypes"
import { toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import {
  ASSET_SCHEDULE_STATUSES,
  FetchAssetScheduleProps,
  fetchAssetsSchedule,
} from "../../../redux/asset_schedule/assetScheduleSlice"
import { getAssetScheduleSelector } from "../../../redux/asset_schedule/selectors"
import {
  AssetScheduleReservation,
  ScheduleAssetDataRow,
} from "../../../redux/asset_schedule/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import AssetIcon from "../../../components/AssetIcon"
import Card from "../../../components/basic/Card"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { createScheduleOption } from "../../../components/Form/options"
import AssetScheduleCell from "../../../components/Manage/AssetScheduleCell"
import { AssetScheduleHeading } from "../../../components/Manage/AssetsHeading"
import ReservationTable, {
  ReservationTableRow,
  TableDataEntryDate,
} from "../../../components/Manage/ReservationTable"
import NoDataFound from "../../../components/NoDataFound"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

type Params = {
  id?: string
  assetType?: string
}

type Props = {
  add?: boolean
}

const { stringify } = queryString

export const ManageAssets = ({ add }: Props) => {
  /**
   * Data
   */
  const { t } = useTranslation()
  const history = useHistory()
  const { search, pathname } = history.location
  const { fromDate, toDate, showWeekends } = useSelector(selectAppDates)
  const { offset, schedule, count, isLoading, isLoaded, error } =
    useAppSelector(getAssetScheduleSelector)
  const { entry: user } = useAppSelector(selectUser)
  const {
    params: { id, assetType: hydratedAssetType },
  } = useRouteMatch<Params>()

  const desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: true,
  })

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())
  const entriesPerPageNum = parseInt(entriesPerPage)

  /**
   * Hooks
   */
  const defaultFilter: Filter = {
    department_id: FilterSpecialValues.ALL,
    building_id: user.building ? user.building.id : FilterSpecialValues.ALL,
    show: ASSET_SCHEDULE_STATUSES.all,
    asset_type: FilterSpecialValues.EMPTY,
    search: "",
    page: 1,
  }

  const [storedFilterValues, saveFilter] = useStoredFilter({
    filterName: ASSETS_FILTER_STORE_NAME,
    defaultFilterValues: defaultFilter,
  })
  const { openModal, closeAllModals } = useModals()

  const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 7,
      maxTrailingLinks: 2,
    })

  const actions = useActions({
    fetchAssetSchedule: (params: FetchAssetScheduleProps) =>
      fetchAssetsSchedule({
        limit: entriesPerPageNum,
        ...params,
      }),
    toggleWeekends: () => toggleWeekends(),
  })

  /**
   * State
   */
  const [assetTypesFilter, setAssetTypesFilter] = useState(
    storedFilterValues.asset_type,
  )
  const reqParams = useRef<Filter>(storedFilterValues)

  const startDate = useRef(fromDate.toISOString())
  const weekEnd = showWeekends ? toDate : toDate.subtract(2, "day")

  const lastDay = dayjs()
    .startOf("date")
    .add(desk_reservation_window_length ?? 7, "day")

  const { data: assetType, isSuccess: areAssetTypesLoaded } =
    useFetchAssetTypeQuery(assetTypesFilter)

  const { data: { results: assets = [] } = {} } = useFetchAssetsQuery(
    {
      asset_type: assetTypesFilter,
    },
    {
      skip: assetTypesFilter.trim().length === 0 || !areAssetTypesLoaded,
    },
  )

  const { fetchDataPagination, setPage, page } = useFetchPaginatedData({
    reqParams: reqParams,
    path: ASSETS_SCHEDULE_PATHNAME,
    fetchCall: actions.fetchAssetSchedule,
    fetchOptions: { start: fromDate.toISOString(), end: toDate.toISOString() },
    offset,
    limit: entriesPerPageNum,
  })

  /**
   * Function and memo
   */

  const handleFilterChange = useCallback(
    async (filter: Filter) => {
      const newFilter = { ...filter, page: 1 }
      updateHistory(pathname, newFilter)

      if (newFilter.asset_type !== reqParams.current.asset_type) {
        setAssetTypesFilter(filter.asset_type)
      }

      /**
       * when building changes, there is no need to fetch new data
       * new data will be fetched on asset type change which will be triggered by building change
       */
      if (newFilter.building_id === reqParams.current.building_id) {
        reqParams.current = newFilter
        history.push(`${pathname}?${stringify(reqParams.current)}`)
        return
      }

      reqParams.current = newFilter
    },
    [history, pathname],
  )

  const tableData: ReservationTableRow<
    ScheduleAssetDataRow,
    AssetScheduleReservation & TableDataEntryDate
  >[] = useMemo(
    () =>
      schedule?.flatMap((a) => {
        const asset = assets?.find((as) => as.id === a.id)
        return asset?.active
          ? {
              header: {
                id: a.id,
                name: a.name,
              },
              data: a.schedule.flatMap((s) =>
                s.reservations.map((r) => ({
                  ...r,
                  date: s.day,
                })),
              ),
            }
          : []
      }),
    [schedule, assets],
  )

  const handleModalClose = () => {
    history.push(ASSETS_SCHEDULE_PATHNAME)
  }

  const bookAsset = (params: Record<string, any> = {}) => {
    history.push({
      pathname: `${ASSETS_SCHEDULE_PATHNAME}/add`,
      search: stringify(params),
    })
  }

  const editAsset = (id: string) => {
    history.push(
      `${ASSETS_SCHEDULE_PATHNAME}/${reqParams.current.asset_type}/${id}`,
    )
  }

  /*
		Main routing logic for create and edit modal views.
	*/
  useEffect(() => {
    if (add) {
      const queryFormData = parseQueryWithDefault(search, {
        start: "",
        end: "",
        date: dayjs().toString(),
        schedule: "Once",
        asset: undefined,
        assetName: undefined,
        scheduleDate: dayjs()
          .add(desk_reservation_window_length ?? 7, "day")
          .toString(),
        tz: timeZone,
      })

      openModal(AssetBookingModal, {
        assetType: reqParams.current.asset_type,
        assetFormData: {
          ...queryFormData,
          date: dayjs(queryFormData.date),
          schedule: createScheduleOption(
            queryFormData.schedule,
            queryFormData.date,
          ),
          scheduleDate: dayjs(queryFormData.scheduleDate),
        },
        dataUpdate: handleModalClose,
      })
    } else if (id && hydratedAssetType) {
      openModal(AssetBookingModal, {
        reservationId: id,
        assetType: hydratedAssetType,
        dataUpdate: handleModalClose,
      })
    } else {
      updateHistory(pathname, reqParams.current)
      fetchDataPagination()
      closeAllModals()
    }
  }, [add, id, hydratedAssetType, search])

  // it will navigate to the first page when there is no results on higher pages
  useEffect(() => {
    if (isLoaded && count > 0 && schedule.length === 0 && page > 1) {
      setPage(1)
    }
  }, [count, isLoaded, page, schedule.length, setPage])

  useEffect(() => {
    const start = fromDate.toISOString()

    if (startDate.current !== start) {
      fetchDataPagination()
      startDate.current = start
    }
  }, [fromDate])

  // saves the current params into the locale store
  useEffect(
    () => () => {
      if (pathname.endsWith(ASSETS_SCHEDULE_PATHNAME)) {
        saveFilter(reqParams.current)
      }
    },
    [saveFilter, pathname],
  )

  const manageAssetsClassName = classNames({
    ManageAssets: true,
    showWeekends: !!showWeekends,
  })

  const isManagerAdmin = isOfficeManager(user) || isPortalAdmin(user)
  const isPaymentRequiredError = error && error.startsWith("Payment required")

  return (
    <View className={manageAssetsClassName}>
      <Header
        fromDate={fromDate}
        toDate={toDate}
        weekEnd={weekEnd}
        showWeekends={showWeekends}
        showExportButton={isManagerAdmin}
        toggleWeekends={actions.toggleWeekends}
        onNewReservation={() => bookAsset()}
        assetTypeId={reqParams.current.asset_type}
        departmentId={reqParams.current.department_id}
      />

      <Space size={0.75} />

      <AssetFilters
        onChange={handleFilterChange}
        defaultValues={storedFilterValues}
      />

      <Space size={0.75} />

      {!isLoading && error && !isPaymentRequiredError && (
        <>
          <NoDataFound>{t("general.reservation.not_loaded")}</NoDataFound>
          <Space size={0.75} />
        </>
      )}

      <ReservationTable
        isLoading={isLoading}
        weekStartDay={fromDate}
        entries={tableData}
        showWeekends={showWeekends}
        renderTableHeadDecorator={() =>
          assetType ? <AssetIcon name={assetType?.icon} size={24} /> : <></>
        }
        renderHead={({ row, index }) => (
          <AssetScheduleHeading
            assetRow={row}
            rowNumber={calcRowNumber(index)}
            key={`head-${index}`}
          />
        )}
        showSummary={false}
        renderCell={({ data, day, row, rowIndex }) => (
          <AssetScheduleCell
            day={day}
            header={row}
            schedule={data}
            onClick={(r) => {
              editAsset(r.id)
            }}
            rowIndex={rowIndex}
            renderVacancy={({ header: _header, day }) =>
              data.length === 0 &&
              (dayjs(day).subtract(1, "day").isBefore(lastDay) ||
                isManagerAdmin) ? (
                <div
                  className="ReservationAvailable"
                  onClick={() => {
                    bookAsset({
                      date: day,
                      asset: row.id,
                      assetName: row.name,
                    })
                  }}
                >
                  {t("desktop.manage.desk_booking.available")}
                </div>
              ) : (
                <></>
              )
            }
          />
        )}
        noResultsMessage={
          <NoDataFound>{t("mobile.general.no_assets")}</NoDataFound>
        }
        pagination={
          <Pagination
            links={paginationLinks}
            setPage={setPage}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            from={from}
            to={to}
            total={count}
            items={"assets"}
            entriesPerPage={parseInt(entriesPerPage)}
            setEntriesPerPage={setEntriesPerPage}
          />
        }
      />
    </View>
  )
}
