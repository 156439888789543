import React from "react"

import InfoOutlineSVG from "../assets/images/icons/InfoOutlined.svg"

import "./MessageInfo.sass"

type Props = {
  children: React.ReactNode
}

const MessageInfo = ({ children }: Props) => {
  return (
    <div className="MessageInfo">
      <InfoOutlineSVG className="InfoIcon" />
      <div className="InfoText">{children}</div>
    </div>
  )
}

export default MessageInfo
