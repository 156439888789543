import { api } from ".."
import { proDvxIntegrationURL } from "../../../api"
import { ProDvxIntegrationRequest, ProDvxIntegrationResponse } from "./types"

export const proDvx = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchProDvxIntegration: builder.query<ProDvxIntegrationResponse, void>({
      query: () => ({
        url: proDvxIntegrationURL(),
      }),
      providesTags: ["ProDvx"],
    }),
    updateProDvxIntegration: builder.mutation<
      ProDvxIntegrationResponse,
      ProDvxIntegrationRequest
    >({
      query: (body) => ({
        url: proDvxIntegrationURL(),
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProDvx"],
    }),
  }),
})

export const {
  useFetchProDvxIntegrationQuery,
  useUpdateProDvxIntegrationMutation,
} = proDvx
