import { t } from "i18next"

import { FEATURE_FLAGS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { ROOM_BOOKING_PATHS } from "./Rooms/constants"

import { selectBuildingsWithVM } from "../../redux/buildings/selectors"
import { useAppSelector } from "../../redux/reducers"

import AuxNav, { AuxButton, MobileAppLinks } from "../../components/AuxNav"

import AssetSVG from "../../assets/images/icons/Asset.svg"
import BadgeSVG from "../../assets/images/icons/BadgeOutline.svg"
import ChairSVG from "../../assets/images/icons/Chair.svg"
import FloorPlanSVG from "../../assets/images/icons/Maps.svg"
import PeopleSVG from "../../assets/images/icons/People.svg"
import RoomSVG from "../../assets/images/icons/Room.svg"

import "./ManageNav.sass"

export const ManageNav = () => {
  const { entries: buildignsWithVM } = useAppSelector(selectBuildingsWithVM)

  const buildingHasVM = buildignsWithVM && buildignsWithVM.length > 0

  const isRoomBookingEnabledFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.ROOM_BOOKING,
  )

  return (
    <AuxNav className="ManageNav">
      <AuxButton to="/manage/people">
        <PeopleSVG />
        <span>{t("general.people")}</span>
      </AuxButton>
      {buildingHasVM && (
        <AuxButton to={"/manage/visitors/visits"} activePath="/manage/visitors">
          <BadgeSVG />
          <span>{t("desktop.settings.visitors.title")}</span>
        </AuxButton>
      )}
      <AuxButton to="/manage/desks">
        <ChairSVG />
        <span>{t("desktop.settings.desks.title")}</span>
      </AuxButton>
      {isRoomBookingEnabledFlag && (
        <AuxButton to={ROOM_BOOKING_PATHS.root}>
          <RoomSVG />
          <span>{t("desktop.settings.rooms.title")}</span>
        </AuxButton>
      )}
      <AuxButton to="/manage/assets">
        <AssetSVG />
        <span>{t("desktop.settings.assets.title")}</span>
      </AuxButton>
      <AuxButton to="/manage/floor-plan">
        <FloorPlanSVG />
        <span>{t("desktop.settings.floor_plans.title_singular")}</span>
      </AuxButton>
      <MobileAppLinks />
    </AuxNav>
  )
}
