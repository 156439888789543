import {
  ChangeEvent,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
} from "react"

import classNames from "classnames"

import { generateString } from "../../../utils"

import CheckSVG from "../../../assets/images/icons/CheckboxCheckmark.svg"
import CrossSVG from "../../../assets/images/icons/Cross.svg"

import "./style.sass"

export type CheckboxProps = {
  className?: string
  label?: ReactNode
  subLabel?: string
  description?: ReactNode
  onChange?: (value: boolean) => void
  value?: boolean
  hasError?: boolean
  name?: string
  disabled?: boolean
  isSecondary?: boolean
  unavailable?: boolean
} & Omit<HTMLAttributes<HTMLInputElement>, "onChange" | "value">

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      onChange,
      label,
      description,
      subLabel,
      value,
      hasError,
      disabled,
      name,
      isSecondary = false,
      unavailable = false,
      ...props
    },
    ref,
  ) => {
    const recognizer = useMemo(() => name || generateString(5), [name])

    const innerOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.checked),
      [onChange],
    )

    return (
      <div
        className={classNames("NewCheckbox", className, {
          checked: unavailable ? false : value,
          disabled,
          error: hasError,
          isSecondary,
        })}
      >
        <div className="control">
          <div className="main">
            <input
              ref={ref}
              {...props}
              id={recognizer}
              type="checkbox"
              checked={unavailable ? false : value}
              disabled={disabled || unavailable}
              onChange={innerOnChange}
            />
            <label htmlFor={recognizer} className="checkbox-label">
              {isSecondary && (
                <>
                  <span
                    className={classNames("checkmark", {
                      visible: value,
                      unavailable,
                    })}
                  >
                    {unavailable ? <CrossSVG /> : <CheckSVG />}
                  </span>
                </>
              )}
              {label}
              {subLabel && <span className="subLabel">{subLabel}</span>}
            </label>
          </div>
          {description && <span className="extra">{description}</span>}
        </div>
      </div>
    )
  },
)

export default Checkbox
