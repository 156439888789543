import { PropsWithChildren } from "react"

import classNames from "classnames"
import {
  ITooltip,
  PlacesType,
  Tooltip as ReactTooltip,
  TooltipWrapper,
} from "react-tooltip"

import { Children } from "../../../types/sharedTypes"
import { generateString } from "../../../utils"

import "./style.sass"

export interface TooltipProps extends Omit<ITooltip, "content"> {
  uniqueId?: string
  place?: PlacesType
  content: Children
  tooltipClassName?: string
}

export const Tooltip = ({
  children,
  uniqueId = generateString(4),
  place = "top",
  content,
  className,
  tooltipClassName,
  ...props
}: PropsWithChildren<TooltipProps>) => {
  const cn = classNames("TpWrapper", className)

  return (
    <TooltipWrapper className={cn} tooltipId={uniqueId}>
      <div className="TpInnerWrapper" id={uniqueId}>
        {children}
      </div>
      <ReactTooltip
        {...props}
        className={tooltipClassName}
        anchorId={uniqueId}
        place={place}
      >
        {content}
      </ReactTooltip>
    </TooltipWrapper>
  )
}
