export const roomReservationsMockData = [
  {
    key: "room1",
    name: "The Synchronization Space Station",
    calendar: {
      id: "calendar1",
      type: "Conference",
    },
    capacity: 6,
    amenities: [
      {
        id: "amenity1",
        name: "Phone",
      },
      {
        id: "amenity2",
        name: "100-in TV",
      },
    ],
    schedule: [
      {
        day: "2025-01-13",
        reservations: [
          {
            id: "9f77cc10-ec80-4f24-bc3c-1a2b4d7073f5",
            title: "Marketing daily",
            start: "2025-01-13T01:00:00Z",
            end: "2025-01-13T02:00:00Z",
            tz: "UTC",
            visibility: "public",
            my: true,
          },
          {
            id: "6c4f19d0-7e75-45a8-9b8b-3f2b748fae9d",
            title: "Team Sync",
            start: "2025-01-13T03:00:00Z",
            end: "2025-01-13T04:00:00Z",
            tz: "UTC",
            visibility: "private",
            my: false,
          },
        ],
      },
    ],
  },
  {
    key: "room2",
    name: "The Brainstorm Bistro",
    calendar: {
      id: "calendar2",
      type: "Meeting",
    },
    capacity: 4,
    amenities: [
      {
        id: "amenity3",
        name: "Whiteboard",
      },
      {
        id: "amenity4",
        name: "Phone charger",
      },
    ],
    schedule: [
      {
        day: "2025-01-14",
        reservations: [
          {
            id: "cf2921f3-b9a6-4cbf-a8a5-6e4c7e3f3c9d",
            title: "Project Kickoff",
            start: "2025-01-14T09:00:00Z",
            end: "2025-01-14T10:00:00Z",
            tz: "UTC",
            visibility: "public",
            my: false,
          },
        ],
      },
    ],
  },
  {
    key: "room3",
    name: "The Collaborative Commons",
    calendar: {
      id: "calendar3",
      type: "Workshop",
    },
    capacity: 3,
    amenities: [
      {
        id: "amenity5",
        name: "LED projector",
      },
      {
        id: "amenity6",
        name: "Coffee machine",
      },
    ],
    schedule: [
      {
        day: "2025-01-15",
        reservations: [
          {
            id: "a5c3e5d8-fc0b-4d7e-99a1-1e7c6d3b7f1d",
            title: "Design Review",
            start: "2025-01-15T11:00:00Z",
            end: "2025-01-15T12:00:00Z",
            tz: "UTC",
            visibility: "public",
            my: true,
          },
          {
            id: "d3e1b5c9-98f8-4d9f-b10e-2a9c3f7d5e6b",
            title: "Client Presentation",
            start: "2025-01-15T13:00:00Z",
            end: "2025-01-15T14:30:00Z",
            tz: "UTC",
            visibility: "private",
            my: false,
          },
        ],
      },
    ],
  },
  {
    key: "room4",
    name: "The Miscape Nexus",
    calendar: {
      id: "calendar4",
      type: "Event",
    },
    capacity: 12,
    amenities: [
      {
        id: "amenity7",
        name: "Panoramic camera",
      },
      {
        id: "amenity8",
        name: "Phone charger",
      },
    ],
    schedule: [
      {
        day: "2025-01-16",
        reservations: [
          {
            id: "b1d7e6a5-8f1c-4d3b-a9f7-5e6b4c1d7e9f",
            title: "Tech Talk",
            start: "2025-01-16T15:00:00Z",
            end: "2025-01-16T16:30:00Z",
            tz: "UTC",
            visibility: "public",
            my: true,
          },
        ],
      },
    ],
  },
  {
    key: "room5",
    name: "Strategy Station",
    calendar: {
      id: "calendar5",
      type: "Board Meeting",
    },
    capacity: 8,
    amenities: [
      {
        id: "amenity9",
        name: "LED projector",
      },
      {
        id: "amenity10",
        name: "Phone",
      },
    ],
    schedule: [
      {
        day: "2025-01-17",
        reservations: [
          {
            id: "e7d5c1f3-9b8f-4a6b-3d7e-5f9b2c4d7e1f",
            title: "Board Meeting",
            start: "2025-01-17T08:00:00Z",
            end: "2025-01-17T09:30:00Z",
            tz: "UTC",
            visibility: "private",
            my: false,
          },
          {
            id: "c9f1d7b5-8e4f-4c3b-a6f7-5e1b7d3c6f9d",
            title: "Quarterly Update",
            start: "2025-01-17T10:00:00Z",
            end: "2025-01-17T11:30:00Z",
            tz: "UTC",
            visibility: "public",
            my: true,
          },
        ],
      },
    ],
  },
]
