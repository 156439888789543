import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router-dom"

import { ENTRIES_PER_PAGE_KEY } from "../../../constants"
import { timeZone } from "../../../dayjs"
import { useBackendPagination } from "../../../hooks/useBackendPagination"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useFetchPaginatedData } from "../../../hooks/useFetchPaginatedData"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import DeskBookingModal from "../../../modals/DeskBookingModal"
import { parseQueryWithDefault, updateHistory } from "../../../utils"
import {
  ENTRIES_PER_PAGE,
  PEOPLE_FILTERS_STORE_NAME,
  PEOPLE_SCHEDULE_PATHNAME,
} from "../constants"
import PeopleFilters, { Filter, FilterKeys } from "./Filters"
import Header from "./Header"
import { PeopleRowHeading } from "./PeopleRowHeading"
import PeopleScheduleCell from "./PeopleScheduleCell"
import { useModals } from "@mattjennings/react-modal-stack"

import { toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import { AVAILABLE_FACETS } from "../../../redux/people_schedule/constants"
import { fetchPeopleSchedule } from "../../../redux/people_schedule/peopleScheduleSlice"
import { selectPeopleSchedule } from "../../../redux/people_schedule/selectors"
import {
  PeopleScheduleEntry,
  PeopleScheduleFacetQuery,
  PeopleScheduleFacets,
  PeopleScheduleReservation,
} from "../../../redux/people_schedule/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import Card from "../../../components/basic/Card"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { createScheduleOption } from "../../../components/Form/options"
import { ReservationSummary } from "../../../components/Manage/ReservationSummary"
import ReservationTable, {
  ReservationTableRow,
  TableDataEntryDate,
} from "../../../components/Manage/ReservationTable"
import NoDataFound from "../../../components/NoDataFound"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

const { stringify } = queryString

export type SchedulePeopleDataRow = Pick<
  PeopleScheduleEntry,
  "email" | "first_name" | "last_name"
>

type Params = {
  id?: string
}

type Props = {
  add?: boolean
}

const DEFAULT_FILTER: Filter = {
  department_id: FilterSpecialValues.ALL,
  show: FilterSpecialValues.ALL,
  search: "",
  page: 1,
}

const queryUseFacets = AVAILABLE_FACETS.map(
  (facet) => `${facet}:all` as PeopleScheduleFacetQuery,
)
const mapFacetToFilters: Record<PeopleScheduleFacets, Partial<FilterKeys>> = {
  people: "show",
  departments: "department_id",
}

const ManagePeople = ({ add }: Props) => {
  const { fromDate, toDate, showWeekends } = useAppSelector(selectAppDates)
  const startDate = useRef(fromDate.toISOString())

  const { entry: user } = useAppSelector(selectUser)
  const {
    offset,
    schedule,
    count,
    isLoading,
    isLoaded,
    error,
    aggregation,
    facets,
  } = useAppSelector(selectPeopleSchedule)

  const desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: true,
  })

  const [storedFilterValues, saveFilter] = useStoredFilter({
    filterName: PEOPLE_FILTERS_STORE_NAME,
    defaultFilterValues: DEFAULT_FILTER,
  })

  const reqParams = useRef<Filter>(storedFilterValues)
  /**
   * State
   */
  const [isOnlyMeFilter, setIsOnlyMeFilter] = useState<boolean>(false)
  const isOfficeManagerOrAdmin = isOfficeManager(user) || isPortalAdmin(user)

  const lastDay = dayjs()
    .startOf("date")
    .add(desk_reservation_window_length ?? 7, "day")
  const weekEnd = showWeekends ? toDate : toDate.subtract(2, "day")
  const managePeopleClassName = classNames("PeopleSchedule", {
    showWeekends: !!showWeekends,
  })

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())
  const entriesPerPageNum = parseInt(entriesPerPage)

  /**
   * Hooks
   */
  const {
    params: { id },
  } = useRouteMatch<Params>()
  const { openModal, closeAllModals } = useModals()
  const { t } = useTranslation()

  const actions = useActions({
    toggleWeekends: () => toggleWeekends(),
    fetchPeopleSchedule: (
      filter: Filter & { offset?: number; start: string; end: string },
    ) => {
      const { page, ...params } = filter

      return fetchPeopleSchedule({
        limit: entriesPerPageNum,
        facets: queryUseFacets,
        ...params,
      })
    },
  })

  const history = useHistory()
  const { search, pathname } = history.location

  const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 7,
      maxTrailingLinks: 2,
    })

  const { fetchDataPagination, setPage, page } = useFetchPaginatedData({
    reqParams: reqParams,
    path: PEOPLE_SCHEDULE_PATHNAME,
    fetchCall: actions.fetchPeopleSchedule,
    fetchOptions: { start: fromDate.toISOString(), end: toDate.toISOString() },
    offset,
    limit: entriesPerPageNum,
  })

  /**
   * Function and memo
   */

  const handleFilterChange = useCallback(
    async ({ department_id, ...filter }: Filter) => {
      reqParams.current = {
        ...filter,
        department_id:
          department_id === "NOT_ASSIGNED"
            ? FilterSpecialValues.NOT_ASSIGNED
            : department_id,
        page: 1,
      }
      updateHistory(pathname, reqParams.current)
      fetchDataPagination()
      setIsOnlyMeFilter(filter.show === FilterSpecialValues.ME)
    },
    [fetchDataPagination, pathname],
  )

  const handleModalClose = () => {
    history.push(PEOPLE_SCHEDULE_PATHNAME)
  }

  const tableData: ReservationTableRow<
    SchedulePeopleDataRow,
    PeopleScheduleReservation & TableDataEntryDate
  >[] = useMemo(
    () =>
      schedule?.flatMap((p) => ({
        header: {
          email: p.email,
          first_name: p.first_name,
          last_name: p.last_name,
          profile: p?.profile,
        },
        data: p.schedule.flatMap((s) =>
          s.reservations.map((r) => ({
            ...r,
            date: s.day,
          })),
        ),
      })),

    [schedule],
  )

  const addReservation = (params: Record<string, any> = {}) => {
    history.push({
      pathname: `${PEOPLE_SCHEDULE_PATHNAME}/add`,
      search: stringify(params),
    })
  }

  const editReservation = ({ id }: PeopleScheduleReservation) => {
    history.push(`${PEOPLE_SCHEDULE_PATHNAME}/${id}`)
  }

  /**
   * Main routing logic for create and edit modal views.
   **/
  useEffect(() => {
    if (add) {
      const queryFormData = parseQueryWithDefault(search, {
        start: "",
        end: "",
        date: dayjs().toString(),
        schedule: "Once",
        user: "",
        userName: "",
        scheduleDate: dayjs()
          .add(desk_reservation_window_length ?? 7, "day")
          .toString(),
        tz: timeZone,
      })

      openModal(DeskBookingModal, {
        formData: {
          ...queryFormData,
          date: dayjs(queryFormData.date),
          schedule: createScheduleOption(
            queryFormData.schedule,
            queryFormData.date,
          ),
          scheduleDate: dayjs(queryFormData.scheduleDate),
        },
        onClose: handleModalClose,
      })
    } else if (id) {
      openModal(DeskBookingModal, {
        reservationId: id,
        onClose: handleModalClose,
      })
    } else {
      if (pathname.endsWith(PEOPLE_SCHEDULE_PATHNAME)) {
        updateHistory(pathname, reqParams.current)
      }
      fetchDataPagination()
      closeAllModals()
    }
  }, [add, id, search])

  // saves the current params into the locale store
  useEffect(
    () => () => {
      if (pathname.endsWith(PEOPLE_SCHEDULE_PATHNAME)) {
        saveFilter(reqParams.current)
      }
    },
    [saveFilter, pathname],
  )

  // it will navigate to the first page when there is no results on higher pages
  useEffect(() => {
    if (isLoaded && count > 0 && schedule.length === 0 && page > 1) {
      setPage(1)
    }
  }, [count, isLoaded, page, schedule.length, setPage])

  useEffect(() => {
    const start = fromDate.toISOString()

    if (startDate.current !== start) {
      fetchDataPagination()
      startDate.current = start
    }
  }, [fromDate])

  const isPaymentRequiredError = error && error.startsWith("Payment required")

  return (
    <View className={managePeopleClassName}>
      <Header
        fromDate={fromDate}
        toDate={toDate}
        weekEnd={weekEnd}
        showWeekends={showWeekends}
        showExportButton={isOfficeManagerOrAdmin}
        toggleWeekends={actions.toggleWeekends}
        onNewReservation={() => addReservation()}
      />

      <Space size={0.75} />

      <PeopleFilters
        onChange={handleFilterChange}
        defaultValues={storedFilterValues}
        facets={facets}
        mapFacetToFilters={mapFacetToFilters}
        useFacets={AVAILABLE_FACETS}
      />

      <Space size={0.75} />

      {error && !isPaymentRequiredError && (
        <>
          <Card className="small-font card-error">
            {t("desktop.manage.desk_booking.reservations_loading_error")}
          </Card>
          <Space size={0.75} />
        </>
      )}

      <ReservationTable
        showSummary
        isLoading={isLoading}
        weekStartDay={fromDate}
        entries={tableData}
        showWeekends={showWeekends}
        renderHead={({ row, index }) => (
          <PeopleRowHeading
            peopleRow={row}
            rowNumber={calcRowNumber(index)}
            key={`head-${index}`}
            isCurrentUser={!isOnlyMeFilter && row.email === user.email}
          />
        )}
        renderCell={({ data, day, row, rowIndex }) => (
          <PeopleScheduleCell
            day={day}
            header={row}
            schedule={data}
            onClick={editReservation}
            rowIndex={rowIndex}
            canEdit={isOfficeManagerOrAdmin || row.email === user.email}
            renderVacancy={({ day }) => {
              const isBefore = dayjs(day).subtract(1, "day").isBefore(lastDay)
              return (
                <>
                  {(row.email === user.email || isOfficeManagerOrAdmin) &&
                    (isBefore || isOfficeManagerOrAdmin) && (
                      <button
                        className="ReservationAvailable"
                        onClick={() => {
                          addReservation({
                            date: day,
                            user: row.email,
                            userFirstName: row.first_name,
                            userLastName: row.last_name,
                          })
                        }}
                      >
                        {t("desktop.manage.desk_booking.available")}
                      </button>
                    )}
                </>
              )
            }}
          />
        )}
        renderSummary={({ day, index }) => (
          <ReservationSummary
            day={day}
            aggregation={aggregation}
            key={`tfoot-${index}`}
          />
        )}
        noResultsMessage={
          <NoDataFound>{t("general.not_found.no_people_found")}</NoDataFound>
        }
        pagination={
          <Pagination
            links={paginationLinks}
            setPage={setPage}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            from={from}
            to={to}
            total={count}
            items={t("desktop.manage.desk_booking.people")}
            entriesPerPage={entriesPerPageNum}
            setEntriesPerPage={setEntriesPerPage}
          />
        }
      />
    </View>
  )
}

export default ManagePeople
