import { useCallback, useEffect, useMemo } from "react"

import { ParseKeys } from "i18next"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router"

import { buildingsURL, floorsURL, roomsURL } from "../../../../api"
import { useNavigation } from "../../../../hooks/useNavigation"
import { useToast } from "../../../../hooks/useToast"
import { IdAndName, OptionType } from "../../../../types/sharedTypes"
import { ROOMS_PATHS } from "../constants"
import { AMENITIES } from "./constants"

import { useUpdateRoomMutation } from "../../../../redux/api/rooms"
import { RoomResponse } from "../../../../redux/api/rooms/types"
import { isApiResponseError, isRejected } from "../../../../redux/api/types"

import AsyncSelect from "../../../../components/advanced/AsyncSelect"
import { Input } from "../../../../components/basic/Input"
import { Select } from "../../../../components/basic/Select"
import Field from "../../../../components/Field"
import { setErrors } from "../../../../components/Form/formUtils"
import PageForm from "../../../../components/Form/PageFormHook"

type FormValues = {
  rooms: RoomResponse[]
  capacity: number
  building: IdAndName | null
  floor: IdAndName | null
  amenities: OptionType[]
}

type Props = {
  rooms: RoomResponse[]
}

const MassEditRoomForm = ({ rooms }: Props) => {
  const [updateRoom] = useUpdateRoomMutation()

  const { push, replace } = useNavigation()

  const history = useHistory()

  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const methods = useForm<FormValues>({
    defaultValues: {
      capacity: 0,
      rooms,
    },
  })

  const {
    control,
    setError,
    formState: { isSubmitting },
    watch,
  } = methods

  const watchedBuilding = watch("building")
  const watchedRooms = watch("rooms")

  useEffect(() => {
    const roomKeys = watchedRooms.map((room) => room.key).join(",")
    replace(ROOMS_PATHS.massEditRooms, { rooms: roomKeys })
  }, [watchedRooms, history])

  const onUpdateClick = useCallback(
    async ({ rooms, capacity, building, floor, amenities }: FormValues) => {
      let updateCount = 0
      for (const room of rooms) {
        const updatedRoom = {
          key: room.key,
          name: room.name ?? "",
          capacity: capacity.toString(),
          building: building?.id ?? null,
          floor: floor?.id ?? null,
          amenities: amenities.map((amenity) => parseInt(amenity.value)),
        }

        const response = await updateRoom(updatedRoom)

        if (isRejected(response)) {
          const { error } = response
          if (isApiResponseError(error)) {
            setErrors(error.formError, setError, errorToast)
            break
          }
        }

        updateCount++
      }

      infoToast(
        t("desktop.settings.rooms.rooms.action_statuses.rooms_updated", {
          count: updateCount,
        }),
      )

      push(ROOMS_PATHS.rooms)

      return
    },
    [updateRoom, infoToast, t, history, setError, errorToast],
  )

  const amenitiesOptions = useMemo(
    () =>
      (
        Object.keys(AMENITIES).filter((v) =>
          isNaN(Number(v)),
        ) as (keyof typeof AMENITIES)[]
      ).map((key) => ({
        label: t(
          `desktop.settings.rooms.rooms.form.amenities.${key.toLowerCase()}` as ParseKeys,
        ),
        value: AMENITIES[key].toString(),
      })),
    [t],
  )

  return (
    <FormProvider {...methods}>
      <PageForm
        className="PairRoomDeviceForm"
        updateMode
        onUpdate={onUpdateClick}
        backUrl={ROOMS_PATHS.rooms}
        disabled={isSubmitting}
      >
        <Field
          control={control}
          name="rooms"
          label={t("desktop.settings.rooms.rooms.form.fields.rooms")}
        >
          {(props) => (
            <AsyncSelect
              urlGenerator={(fetchOptions) => {
                return roomsURL(fetchOptions)
              }}
              nothingFoundMessage={t(
                "desktop.settings.rooms.rooms.form.fields.not_found.rooms",
              )}
              getOptionLabel={(room) => room.name}
              getOptionValue={(room) => room.key}
              fetchLimit={10000}
              isMulti
              clearable
              {...props}
            />
          )}
        </Field>
        <>
          <Field
            control={control}
            name="capacity"
            label={t("desktop.settings.rooms.rooms.form.fields.capacity")}
            rules={{
              required: t(
                "desktop.settings.rooms.rooms.form.fields.capacity_required",
              ),
            }}
          >
            {(props) => <Input {...props} />}
          </Field>

          <Field
            control={control}
            name="amenities"
            label={t("general.amenities")}
          >
            {(props) => (
              <Select
                {...props}
                isMulti
                options={amenitiesOptions}
                disabled={isSubmitting}
                clearable
                nothingFoundMessage={t("general.not_found.no_options_found")}
              />
            )}
          </Field>

          <Field
            control={control}
            name="building"
            label={t("desktop.settings.assets.asset_type_form.building")}
          >
            {(props) => (
              <AsyncSelect
                urlGenerator={(fetchOptions) => {
                  return buildingsURL(fetchOptions)
                }}
                nothingFoundMessage={t(
                  "desktop.settings.rooms.rooms.form.fields.not_found.building",
                )}
                getOptionLabel={(building) => building.name}
                getOptionValue={(building) => building.id}
                fetchLimit={10000}
                clearable
                {...props}
              />
            )}
          </Field>

          {watchedBuilding?.id && (
            <Field
              control={control}
              name="floor"
              label={t("mobile.book.floor")}
            >
              {(props) => (
                <AsyncSelect
                  urlGenerator={(fetchOptions) => {
                    return floorsURL({
                      query: {
                        conditions: {
                          building_id: watchedBuilding.id,
                        },
                      },
                      ...fetchOptions,
                    })
                  }}
                  key={watchedBuilding.id}
                  nothingFoundMessage={t(
                    "desktop.settings.rooms.rooms.form.fields.not_found.floor",
                  )}
                  getOptionLabel={(floor) => floor.name}
                  getOptionValue={(floor) => floor.id}
                  fetchLimit={10000}
                  clearable
                  {...props}
                />
              )}
            </Field>
          )}
        </>
      </PageForm>
    </FormProvider>
  )
}

export default MassEditRoomForm
