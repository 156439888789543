import { api } from "../"
import { analyticsEvent, SupportedEvents } from "../../../analytics"
import {
  deskReservationCheckin,
  deskReservationCheckout,
  deskReservationsGetURL,
  deskReservationURL,
} from "../../../api"
import { RootState } from "../../reducers"
import { ReservationActionType } from "../../reservations/types"
import { PaginatedResponse } from "../../types"
import {
  CreateDeskReservation,
  DeskReservation,
  FetchDeskReservationsProps,
  RecurringReservationResponse,
} from "./types"

import { FilterSpecialValues } from "../../../components/Filter/types"

const FETCH_DEFAULTS = {
  limit: 20,
  offset: 0,
}

export const deskReservations = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchDeskReservations: builder.query<
      PaginatedResponse<DeskReservation>,
      FetchDeskReservationsProps | void
    >({
      query: ({ department_id, ...options } = {}) => ({
        url: deskReservationsGetURL({
          ...FETCH_DEFAULTS,
          ...options,
          ...(department_id &&
            department_id !== FilterSpecialValues.ALL && {
              department_id:
                department_id === FilterSpecialValues.EMPTY
                  ? ""
                  : department_id,
            }),
        }),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "DeskReservations" as const,
                id,
              })),
              { type: "DeskReservations", id: "LIST" },
            ]
          : [{ type: "DeskReservations", id: "LIST" }],
    }),

    fetchMyDeskReservations: builder.query<
      PaginatedResponse<DeskReservation>,
      FetchDeskReservationsProps | void
    >({
      query: (options) => ({
        url: deskReservationsGetURL({
          ...FETCH_DEFAULTS,
          ...options,
          show: "my",
        }),
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "DeskReservations" as const,
                id,
              })),
              { type: "DeskReservations", id: "LIST" },
            ]
          : [{ type: "DeskReservations", id: "LIST" }],
    }),

    fetchDeskReservation: builder.query<DeskReservation, string>({
      query: (id) => ({
        url: deskReservationURL(id),
      }),
      providesTags: (_result, _error, arg) => [
        { type: "DeskReservations", id: arg },
      ],
    }),

    createDeskReservation: builder.mutation<
      DeskReservation | RecurringReservationResponse,
      CreateDeskReservation
    >({
      query: (body) => ({
        url: deskReservationURL(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "DeskReservations", id: "LIST" }],
      onQueryStarted: async (arg, { getState, queryFulfilled }) => {
        try {
          const result = await queryFulfilled
          const { data } = result
          const {
            user: { entry: user },
          } = getState() as RootState

          if (arg.user_email === user.email) {
            analyticsEvent(SupportedEvents.DESK_BOOKED, {
              id: arg.desk_id,
              name: "desk" in data ? (data?.desk?.name ?? "") : "",
            })
          } else {
            analyticsEvent(SupportedEvents.DESK_BOOKED_FOR_OTHERS, {
              id: arg.desk_id,
              name: "desk" in data ? (data?.desk?.name ?? "") : "",
            })
          }
        } catch {}
      },
    }),

    updateDeskReservation: builder.mutation<
      DeskReservation,
      {
        id: string
        type?: ReservationActionType
        payload: Partial<CreateDeskReservation>
      }
    >({
      query: ({ id, type = ReservationActionType.CURRENT, payload }) => ({
        url: deskReservationURL(id, type),
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "DeskReservations", id: "LIST" },
        { type: "DeskReservations", id },
      ],
    }),

    deleteDeskReservation: builder.mutation<
      void,
      { id: string; type?: ReservationActionType }
    >({
      query: ({ id, type = ReservationActionType.CURRENT }) => ({
        url: deskReservationURL(id, type),
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: "DeskReservations", id: "LIST" },
        { type: "DeskReservations", id },
      ],
    }),

    checkinDeskReservation: builder.mutation<void, string>({
      query: (id) => ({
        url: deskReservationCheckin(id),
        method: "POST",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "DeskReservations", id: "LIST" },
        { type: "DeskReservations", id },
      ],
    }),

    checkoutDeskReservation: builder.mutation<void, string>({
      query: (id) => ({
        url: deskReservationCheckout(id),
        method: "POST",
      }),
      invalidatesTags: (_result, _error, id) => [
        { type: "DeskReservations", id: "LIST" },
        { type: "DeskReservations", id },
      ],
    }),
  }),
})

export const {
  useFetchDeskReservationsQuery,
  useFetchMyDeskReservationsQuery,
  useLazyFetchMyDeskReservationsQuery,
  useFetchDeskReservationQuery,
  useCreateDeskReservationMutation,
  useUpdateDeskReservationMutation,
  useDeleteDeskReservationMutation,
  useCheckinDeskReservationMutation,
  useCheckoutDeskReservationMutation,
} = deskReservations
