import dayjs from "dayjs"
import { TFunction } from "i18next"
import { Trans } from "react-i18next"

import { NotificationCardProps } from ".."
import { ROOMS_PATHNAME } from "../../../../screens/Settings/Rooms/constants"
import { getLabel } from "../../../../utils"
import Button from "../../../basic/Button"
import { SensitivityOptions } from "../types"
import { EVENT_ICON, EVENT_SENSITIVITY } from "./constants"
import Date from "./Content/Date"
import Location from "./Content/Location"
import Time from "./Content/Time"

import {
  ASSET_EVENTS,
  ASSET_RESERVATION_RELEASED,
  DESK_EVENTS,
  DESK_RESERVATION_RELEASED,
  INVITE_CANCELLED_HOST,
  INVITE_CHECKED_IN_HOST,
  INVITE_CREATED_HOST,
  INVITE_UPDATED_HOST,
  Notification,
  NotificationType,
  SYSTEM_CALENDAR_OUT_OF_SYNC,
  SYSTEM_EVENTS,
  SYSTEM_PREPAID_CODE_TO_EXPIRE,
  VISITOR_EVENTS,
} from "../../../../redux/api/notifications/types"

import "./style.sass"

type ActionGeneratorUtility = {
  push: (pathname: string) => void
  t: TFunction<"translation", undefined>
  isMobile: boolean
}

type NotificationCardGenerator = {
  notification: Notification
  utilities: ActionGeneratorUtility
}

type GeneratedProps = Omit<
  NotificationCardProps,
  "listId" | "discardable"
> | null

const generateActions = (
  type: NotificationType,
  { push, t, isMobile }: Partial<ActionGeneratorUtility>,
) => {
  switch (type) {
    case DESK_EVENTS.RESERVATION_RELEASED: {
      const pathName = isMobile ? "/book/desk/" : "/manage/floor-plan"
      return (
        <Button variant="link" onClick={() => push?.(pathName)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case ASSET_EVENTS.RESERVATION_RELEASED: {
      const pathName = isMobile ? "/home/reservations" : "/manage/assets"
      return (
        <Button variant="link" onClick={() => push?.(pathName)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    case SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC: {
      if (isMobile) return
      return (
        <Button variant="link" onClick={() => push?.(ROOMS_PATHNAME)}>
          {t?.(`desktop.settings.profile.notifications.cards.${type}.action`)}
        </Button>
      )
    }
    default:
      return null
  }
}

const getNotificationSensitivity = (
  type: NotificationType,
  seen_at: Notification["seen_at"],
) => {
  if (seen_at !== null) {
    return SensitivityOptions.NONE
  }

  return EVENT_SENSITIVITY[type] || SensitivityOptions.NORMAL
}

const generateNotificationCardProps = ({
  notification,
  utilities,
}: NotificationCardGenerator): GeneratedProps => {
  const { id, type, seen_at, message, created_at } = notification
  const { push, t, isMobile } = utilities

  const PREPOPULATED_VALUES = {
    id,
    icon: EVENT_ICON[type],
    actions: generateActions(type, {
      push,
      t,
      isMobile,
    }),
    sensitivity: getNotificationSensitivity(type, seen_at),
    dismissable: seen_at === null,
    subtitle: dayjs(created_at).format("MMM DD, YYYY · HH:mm"),
  }

  switch (type) {
    case VISITOR_EVENTS.INVITE_CREATED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CREATED_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
          </>
        ),
        type,
      }
    }
    case VISITOR_EVENTS.INVITE_UPDATED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_UPDATED_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
          </>
        ),
        type,
      }
    }
    case VISITOR_EVENTS.INVITE_CHECKED_IN_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CHECKED_IN_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
          </>
        ),
        type,
      }
    }
    case VISITOR_EVENTS.INVITE_CANCELLED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CANCELLED_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: visitor.full_name,
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case DESK_EVENTS.RESERVATION_RELEASED: {
      const { desk, start, end, tz } = message as DESK_RESERVATION_RELEASED
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: desk.name,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: desk.name,
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={desk.building} floor={desk.floor} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case ASSET_EVENTS.RESERVATION_RELEASED: {
      const { asset, start, end, tz } = message as ASSET_RESERVATION_RELEASED
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: asset.name,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: asset.name,
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={asset.building} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    case SYSTEM_EVENTS.PREPAID_CODE_TO_EXPIRE: {
      const { prepaid_code } = message as SYSTEM_PREPAID_CODE_TO_EXPIRE
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: prepaid_code.code,
        }),
        content: (
          <>
            <div className="description-row">
              <div>
                <Trans
                  i18nKey={`desktop.settings.profile.notifications.cards.${type}.subtitle`}
                >
                  <a
                    href={`mailto:${getLabel("links.salesEmail")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    link
                  </a>
                </Trans>
              </div>
            </div>
          </>
        ),
        type,
      }
    }
    case SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC: {
      const { rooms } = message as SYSTEM_CALENDAR_OUT_OF_SYNC
      const remainingRooms = rooms.length - 3
      const moreRoomsText =
        remainingRooms > 0
          ? `+ ${t(`desktop.settings.profile.notifications.cards.${type}.subtitle_2`, { number: remainingRooms })}`
          : ""
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
              )}
            </div>
            <div className="description-row">
              <div>
                <ul>
                  {rooms.slice(0, 3).map((r) => (
                    <li key={r.id}>{r.name}</li>
                  ))}
                </ul>
                {Boolean(moreRoomsText) && (
                  <div className="more-rooms-text">{moreRoomsText}</div>
                )}
              </div>
            </div>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_3`,
              )}
            </div>
          </>
        ),
        type,
      }
    }
    default: {
      return null
    }
  }
}

export default generateNotificationCardProps
