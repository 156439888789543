import { MouseEvent, useCallback } from "react"

import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import { buildingsURL } from "../../api"
import { useToast } from "../../hooks/useToast"
import AssetTypeDeleteModal from "../../modals/AssetTypeDeleteModal"
import AsyncSelect from "../advanced/AsyncSelect"
import { AssetIconNames } from "../AssetIcon"
import AssetIconPicker from "../AssetIconPicker"
import { Input } from "../basic/Input"
import Field from "../Field"
import { setErrors } from "./formUtils"
import ModalForm from "./ModalFormHook"
import { useModals } from "@mattjennings/react-modal-stack"

import {
  useCreateAssetTypeMutation,
  useFetchAssetTypesQuery,
  useUpdateAssetTypeMutation,
} from "../../redux/api/assetTypes"
import { isApiResponseError, isRejected } from "../../redux/api/types"
import { AssetType } from "../../redux/asset_types/types"
import { BuildingResponse } from "../../redux/buildings/types"

import "./AssetTypeForm.sass"

type Props = {
  assetType?: AssetType
  onChangeShouldCloseModal: (shouldCloseOnEscape: boolean) => void
}

type FormValues = {
  icon: AssetIconNames
  name: string
  description: string
  buildings: BuildingResponse | undefined
}

const AssetTypeForm = ({ assetType, onChangeShouldCloseModal }: Props) => {
  const { closeModal, openModal } = useModals()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()

  const { id, icon, name, description, buildings = [] } = assetType || {}

  const methods = useForm<FormValues>({
    defaultValues: {
      icon: icon,
      name: name ?? "",
      description: description ?? "",
      buildings: buildings[0],
    },
  })
  const {
    setError,
    control,
    formState: { isSubmitting },
  } = methods

  const { data: { count: assetTypeCount = 0 } = {} } = useFetchAssetTypesQuery()
  const [createAssetType] = useCreateAssetTypeMutation()
  const [updateAssetType] = useUpdateAssetTypeMutation()

  const getBuildingId = (buildings?: BuildingResponse) =>
    buildings?.id ? [buildings.id] : undefined

  const onCreateClick = useCallback(
    async ({ name, description, icon, buildings }: FormValues) => {
      const response = await createAssetType({
        name,
        description,
        icon: icon,
        buildings: getBuildingId(buildings),
      })

      if (isRejected(response)) {
        const { error } = response

        if (isApiResponseError(error)) {
          setErrors(error.formError, setError, errorToast)
        }
      } else {
        const { data: assetType } = response

        analyticsEvent(SupportedEvents.ASSET_TYPE_ADD, {
          id: assetType.id,
          name: assetType.name,
          icon: assetType.icon,
          total: assetTypeCount + 1,
        })

        infoToast(
          t("desktop.settings.assets.asset_type_form.asset_type_created_toast"),
        )
        closeModal()
      }
    },
    [
      assetTypeCount,
      createAssetType,
      setError,
      errorToast,
      infoToast,
      t,
      closeModal,
    ],
  )

  const onUpdateClick = useCallback(
    async ({ name, description, icon, buildings }: FormValues) => {
      if (id && icon) {
        const response = await updateAssetType({
          id,
          name,
          description,
          icon,
          buildings: getBuildingId(buildings),
        })

        if (isRejected(response)) {
          const { error } = response

          if (isApiResponseError(error)) {
            setErrors(error.formError, setError, errorToast)
          }
        } else {
          const { data: assetType } = response

          analyticsEvent(SupportedEvents.ASSET_TYPE_UPDATE, {
            id: assetType.id,
            name: assetType.name,
            icon: assetType.icon,
          })

          infoToast(
            t(
              "desktop.settings.assets.asset_type_form.asset_type_updated_toast",
            ),
          )
          closeModal()
        }
      }
    },
    [id, updateAssetType, setError, errorToast, infoToast, t, closeModal],
  )

  const onDeleteClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault()
      if (assetType) {
        openModal(AssetTypeDeleteModal, { assetType })
      }
    },
    [assetType, openModal],
  )

  const updateMode = Boolean(assetType)

  /*
   * all new forms should be wrapped in FormProvider
   * in case of modal form use new ModalForm form ModalFormHook as it uses the form context to handle submit
   */

  return (
    <FormProvider {...methods}>
      <ModalForm
        className="asset-type-form"
        updateMode={updateMode}
        title={
          updateMode
            ? t("desktop.settings.assets.asset_type_form.edit_asset_type")
            : t("desktop.settings.assets.asset_type_form.new_asset_type")
        }
        onCreate={onCreateClick}
        onUpdate={onUpdateClick}
        onDelete={onDeleteClick}
        hasConfirmationPrompt={false}
      >
        <Field control={control} name="icon">
          {(props) => (
            <AssetIconPicker
              onChangeShouldCloseModal={onChangeShouldCloseModal}
              {...props}
            />
          )}
        </Field>
        <Field
          control={control}
          name="name"
          label={t("desktop.settings.assets.asset_type_form.name")}
        >
          {(props) => (
            <Input
              autoFocus
              maxLength={60}
              disabled={isSubmitting}
              {...props}
            />
          )}
        </Field>
        <Field
          control={control}
          name="description"
          label={t("desktop.settings.assets.asset_type_form.description")}
          subText={t("general.optional")}
        >
          {(props) => (
            <Input disabled={isSubmitting} maxLength={200} {...props} />
          )}
        </Field>
        <Field
          control={control}
          name="buildings"
          label={t("desktop.settings.assets.asset_type_form.building")}
        >
          {(props) => (
            <AsyncSelect
              urlGenerator={(fetchOptions) => {
                return buildingsURL(fetchOptions)
              }}
              nothingFoundMessage={t("general.not_found.no_options_found")}
              getOptionLabel={(building) => building.name}
              getOptionValue={(building) => building.id}
              {...props}
            />
          )}
        </Field>
      </ModalForm>
    </FormProvider>
  )
}

export default AssetTypeForm
