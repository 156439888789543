import React from "react"

import { Redirect, Switch as RouteSwitch } from "react-router-dom"

import AssetsPage from "./Assets"
import { DESKS_PATHS } from "./constants"
import DesksPage from "./Desks"
import PeoplePage from "./People"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Desks = () => {
  return (
    <RouteSwitch>
      {/*<ProtectedRoute exact path={DESKS_PATHS.people} component={PeoplePage} />*/}
      <ProtectedRoute exact path={DESKS_PATHS.desks} component={DesksPage} />
      {/*<ProtectedRoute exact path={DESKS_PATHS.assets} component={AssetsPage} />*/}

      <Redirect to={DESKS_PATHS.desks} />
    </RouteSwitch>
  )
}

export default Desks
