import React from "react"

import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { LANGUAGE_OPTIONS, TIME_FORMAT_OPTIONS } from "../../../../../constants"
import { SETTINGS_GROUPS, SettingsGroups } from "../constants"
import { EffectiveSettingsLocation } from "../types"
import SettingsGroup from "./index"

import { Select } from "../../../../../components/basic/Select"
import Field from "../../../../../components/Field"
import { TIMEZONE_OPTIONS } from "../../../../../components/Form/options"

type Props = {
  checked: boolean
  onToggleSettingsGroup: (
    settingsGroup: SettingsGroups,
    checked: boolean,
  ) => void
  effectiveSettingsLocation?: EffectiveSettingsLocation
  onLocationClick?: (location: EffectiveSettingsLocation) => void
  isSwitchHidden?: boolean
}

const LocalizationGroup = ({
  checked,
  onToggleSettingsGroup,
  effectiveSettingsLocation,
  onLocationClick,
  isSwitchHidden,
}: Props) => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <SettingsGroup
      label={t(
        "desktop.settings.rooms.device_settings.form.localization.title",
      )}
      checked={checked}
      onChange={(checked) =>
        onToggleSettingsGroup(SETTINGS_GROUPS.LOCALIZATION_GROUP, checked)
      }
      effectiveSettingsLocation={effectiveSettingsLocation}
      onLocationClick={onLocationClick}
      isSwitchHidden={isSwitchHidden}
      id={SETTINGS_GROUPS.LOCALIZATION_GROUP}
    >
      <div className="FieldWrapper Subtext">
        {t(
          "desktop.settings.rooms.device_settings.form.localization.description",
        )}
      </div>

      <Field
        control={control}
        name="timezone"
        label={t(
          "desktop.settings.rooms.device_settings.form.localization.timezone_label",
        )}
        className="field-width-50"
      >
        {(props) => (
          <Select
            {...props}
            options={TIMEZONE_OPTIONS}
            nothingFoundMessage={t("general.not_found.no_options_found")}
          />
        )}
      </Field>

      <Field
        control={control}
        name="language"
        label={t(
          "desktop.settings.rooms.device_settings.form.localization.language_label",
        )}
        className="field-width-50"
      >
        {(props) => (
          <Select
            {...props}
            options={LANGUAGE_OPTIONS}
            nothingFoundMessage={t("general.not_found.no_options_found")}
          />
        )}
      </Field>

      <Field
        control={control}
        name="timeformat"
        label={t(
          "desktop.settings.rooms.device_settings.form.localization.time_format_label",
        )}
        className="field-width-50"
      >
        {(props) => (
          <Select
            {...props}
            options={TIME_FORMAT_OPTIONS}
            nothingFoundMessage={t("general.not_found.no_options_found")}
          />
        )}
      </Field>
    </SettingsGroup>
  )
}

export default LocalizationGroup
