import { useMemo } from "react"

import classNames from "classnames"
import dayjs from "dayjs"

import { TABLET_LOW_BATTERY_THRESHOLD } from "../../constants"

import {
  ConnectivityInterface,
  RoomDeviceResponse,
} from "../../redux/api/devices/types"

import AccessPointSVG from "../../assets/images/icons/AccessPoint.svg"
import Battery40SVG from "../../assets/images/icons/Battery40.svg"
import Battery60SVG from "../../assets/images/icons/Battery60.svg"
import Battery80SVG from "../../assets/images/icons/Battery80.svg"
import Battery100SVG from "../../assets/images/icons/Battery100.svg"
import BatteryChargingSVG from "../../assets/images/icons/BatteryCharging.svg"
import BatteryCriticalSVG from "../../assets/images/icons/BatteryCritical.svg"
import EthernetCableSVG from "../../assets/images/icons/Cable.svg"
import NoWifiSVG from "../../assets/images/icons/NoWifi.svg"
import WifiOneBarSVG from "../../assets/images/icons/Wifi1Bar.svg"
import WifiTwoBarSVG from "../../assets/images/icons/Wifi2Bar.svg"
import WifiThreeBarSVG from "../../assets/images/icons/Wifi3Bar.svg"

import "./RoomDeviceStatus.sass"

type deviceBatteryStatusProps = {
  batteryStatus: number
  batteryCharging?: boolean
  isStatusOutdated?: boolean
}

export const DeviceBatteryStatus = ({
  batteryStatus,
  batteryCharging,
  isStatusOutdated,
}: deviceBatteryStatusProps) => {
  let icon = useMemo(() => {
    if (batteryCharging) {
      return <BatteryChargingSVG />
    } else {
      if (batteryStatus <= TABLET_LOW_BATTERY_THRESHOLD) {
        return <BatteryCriticalSVG />
      } else if (batteryStatus <= 40) {
        return <Battery40SVG />
      } else if (batteryStatus <= 60) {
        return <Battery60SVG />
      } else if (batteryStatus <= 80) {
        return <Battery80SVG />
      }
      return <Battery100SVG />
    }
  }, [batteryCharging, batteryStatus])

  const iconClasses = classNames({
    icon: true,
    warning: batteryStatus <= TABLET_LOW_BATTERY_THRESHOLD,
    outdated: isStatusOutdated,
  })

  return (
    <div className="status">
      <span className={iconClasses}>{icon}</span>
      {!batteryCharging && (
        <span className="label">{batteryStatus > 0 ? batteryStatus : 0}%</span>
      )}
    </div>
  )
}

type DeviceConnectivityProps = {
  connectivityInterface: ConnectivityInterface | null
  wifiQuality: number | null
  isStatusOutdated?: boolean
}

export const DeviceConnectivityStatus = ({
  connectivityInterface,
  wifiQuality,
  isStatusOutdated,
}: DeviceConnectivityProps) => {
  const icon = useMemo(() => {
    switch (connectivityInterface) {
      case ConnectivityInterface.ETHERNET:
        return <EthernetCableSVG />
      case ConnectivityInterface.MOBILE:
        return <AccessPointSVG />
      case ConnectivityInterface.WIFI:
        if (wifiQuality === null || wifiQuality < 1) {
          return <NoWifiSVG />
        } else if (wifiQuality <= 25) {
          return <WifiOneBarSVG />
        } else if (wifiQuality > 25 && wifiQuality < 70) {
          return <WifiTwoBarSVG />
        } else {
          return <WifiThreeBarSVG />
        }
      default:
        return null
    }
  }, [wifiQuality, connectivityInterface])

  const iconClasses = classNames({
    icon: true,
    warning:
      connectivityInterface === ConnectivityInterface.WIFI &&
      (wifiQuality === null || wifiQuality < 1),
    outdated: isStatusOutdated,
  })

  return (
    <div className="status">
      <span className={iconClasses}>{icon}</span>
    </div>
  )
}

type DeviceStatusProps = {
  device: RoomDeviceResponse
}

const RoomDeviceStatus = ({ device }: DeviceStatusProps) => {
  const { status_sent_at, wifi_quality, battery, connectivity_interface } =
    device

  const isStatusOutdated = status_sent_at
    ? dayjs().diff(dayjs(status_sent_at), "day") > 30
    : false

  return (
    <div className="RoomDeviceStatus">
      <DeviceConnectivityStatus
        connectivityInterface={connectivity_interface}
        isStatusOutdated={isStatusOutdated}
        wifiQuality={wifi_quality}
      />

      <DeviceBatteryStatus
        isStatusOutdated={isStatusOutdated}
        batteryStatus={battery}
      />
    </div>
  )
}

export default RoomDeviceStatus
