import React from "react"

import { Redirect, Switch as RouteSwitch } from "react-router-dom"

import { VISITORS_PATHS } from "./constants"
import DailyOverview from "./DailyOverview"
import Dashboard from "./Dashboard"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Visitors = () => {
  return (
    <RouteSwitch>
      <ProtectedRoute
        exact
        path={VISITORS_PATHS.dashboard}
        component={Dashboard}
      />
      {/*<ProtectedRoute
        exact
        path={VISITORS_PATHS.dailyOverview}
        component={DailyOverview}
      />*/}

      <Redirect to={VISITORS_PATHS.dashboard} />
    </RouteSwitch>
  )
}

export default Visitors
