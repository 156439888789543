import React from "react"

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"

import { FEATURE_FLAGS, PERMISSIONS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { useCheckForPermission } from "../../hooks/useCheckForPermission"
import Labs from "../Labs"
import NotFound from "../NotFound"
import Assets from "./Assets/Assets"
import Billing from "./Billing"
import { BILLING_PATHS } from "./Billing/constants"
import { BUILDINGS_PATHS } from "./Buildings/constants"
import Buildings from "./Buildings/index"
import { DEPARTMENT_PATHS } from "./Departments/constants"
import Departments from "./Departments/Departments"
import EditDepartments from "./Departments/EditDepartments"
import Desks from "./Desks"
import { DESKS_PATHS } from "./Desks/constants"
import FloorPlans from "./FloorPlans"
import Health from "./Health"
import HealthSettings from "./HealthSettings"
import Integrations from "./Integrations"
import { INTEGRATIONS_PATHS } from "./Integrations/constants"
import Organization from "./Organization"
import { ORGANIZATION_PATHS } from "./Organization/constants"
import Overview from "./Overview"
import { OVERVIEW_PATH } from "./Overview/constants"
import Rooms from "./Rooms"
import { ROOMS_PATHS } from "./Rooms/constants"
import { SettingsNav } from "./SettingsNav"
import { USER_DIRECTORY_PATHS } from "./UserDirectory/constants"
import UserDirectory from "./UserDirectory/index"
import AccountSettings from "./UserProfile"
import { ACCOUNT_PATHS } from "./UserProfile/constants"
import Visitors from "./Visitors"
import { VISITORS_PATHS } from "./Visitors/constants"

import { getAppParams } from "../../redux/appParams/selectors"
import { useAppSelector } from "../../redux/reducers"
import { selectUser } from "../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../redux/user/utils"

import ProtectedRoute from "../../components/ProtectedRoute"
import RouteView from "../../components/RouteView"
import Screen from "../../components/Screen"

function Settings() {
  const canEditFloor = useCheckForPermission(
    PERMISSIONS.floorPlans.canChangeFloor,
  )
  const editFloorPermission = PERMISSIONS.floorPlans.canChangeFloor
  const editDepartmentPermission = PERMISSIONS.departments.canChangeDepartment
  const editDeskPermission = PERMISSIONS.desks.canChangeDesk
  const editAmenityPermission = PERMISSIONS.amenities.canChangeAmenity
  const editHealthPermission = PERMISSIONS.health.canChangeHealth

  const isDeleteSurvey = useRouteMatch(
    ORGANIZATION_PATHS.company_details_survey,
  )

  const showSidebar = !isDeleteSurvey

  const { isAppWhitelabel } = useAppSelector(getAppParams)

  const { entry: currentUser } = useAppSelector(selectUser)
  const isAdmin = isPortalAdmin(currentUser)
  const isLabsEligible = isAdmin

  const isManager = isOfficeManager(currentUser)
  const hasOfficeManagerRoomFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.OFFICE_MANAGER_ROOM_VISIBILITY,
  )

  return (
    <RouteView className="Settings">
      <Screen sidebar={(showSidebar && <SettingsNav />) || undefined}>
        <Switch>
          {isAdmin && (
            <ProtectedRoute path={OVERVIEW_PATH} component={Overview} />
          )}

          <ProtectedRoute
            exact
            path="/settings/floor-plans"
            accessPermission={editFloorPermission}
            component={FloorPlans}
          />

          <ProtectedRoute
            exact
            path={DEPARTMENT_PATHS.root}
            accessPermission={editDepartmentPermission}
            component={Departments}
          />
          <ProtectedRoute
            exact
            path={`${DEPARTMENT_PATHS.edit}/:department_id`}
            accessPermission={editDepartmentPermission}
            component={EditDepartments}
          />
          <ProtectedRoute
            exact
            path={DEPARTMENT_PATHS.add}
            accessPermission={editDepartmentPermission}
            component={EditDepartments}
          />

          <ProtectedRoute path={DESKS_PATHS.root} component={Desks} />

          <ProtectedRoute path={BUILDINGS_PATHS.root} component={Buildings} />

          <ProtectedRoute
            path={USER_DIRECTORY_PATHS.root}
            component={UserDirectory}
          />

          <ProtectedRoute
            path={ORGANIZATION_PATHS.root}
            component={Organization}
          />

          <ProtectedRoute
            path={INTEGRATIONS_PATHS.root}
            component={Integrations}
          />

          {(isAdmin || (isManager && hasOfficeManagerRoomFlag)) && (
            <ProtectedRoute path={ROOMS_PATHS.root} component={Rooms} />
          )}

          <ProtectedRoute
            exact
            path="/settings/assets"
            accessPermission={editAmenityPermission}
            component={Assets}
          />

          <ProtectedRoute
            exact
            path="/settings/health"
            accessPermission={editHealthPermission}
            component={Health}
          />
          <ProtectedRoute
            exact
            path="/settings/health/edit/:id?"
            accessPermission={editHealthPermission}
            component={HealthSettings}
          />
          <ProtectedRoute
            exact
            path="/settings/health/new/:building_id?"
            accessPermission={editHealthPermission}
            component={HealthSettings}
          />

          {!isAppWhitelabel && (
            <ProtectedRoute path={VISITORS_PATHS.root} component={Visitors} />
          )}

          <ProtectedRoute path={BILLING_PATHS.root} component={Billing} />

          {isLabsEligible && (
            <ProtectedRoute exact path="/settings/labs" component={Labs} />
          )}

          <ProtectedRoute
            path={ACCOUNT_PATHS.root}
            component={AccountSettings}
          />

          {/*
						------------------
						TODO: after completing JOAN-5317 remove redirects
					*/}
          <Route
            exact
            path="/settings/desks"
            render={() => <Redirect to={DESKS_PATHS.layout} />}
          />
          <Route
            exact
            path="/settings/general"
            render={() => <Redirect to={DESKS_PATHS.general} />}
          />
          <Route
            exact
            path="/settings/time-slots)"
            render={() => <Redirect to={DESKS_PATHS.timeSlots.default} />}
          />
          <Route
            exact
            path="/settings/amenities"
            render={() => <Redirect to={DESKS_PATHS.amenities.default} />}
          />
          {/*
						------------------
					*/}
          <Route
            exact
            path="/settings"
            render={() => (
              <Redirect
                to={
                  canEditFloor
                    ? isAdmin
                      ? OVERVIEW_PATH
                      : "/settings/floor-plans"
                    : "/settings/profile"
                }
              />
            )}
          />
          <Route render={() => <NotFound onlyView />} />
        </Switch>
      </Screen>
    </RouteView>
  )
}

export default Settings
