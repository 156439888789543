import { Children } from "../../../../types/sharedTypes"
import { Sensitivity, SensitivityOptions } from "../types"

import {
  ASSET_EVENTS,
  DESK_EVENTS,
  SYSTEM_EVENTS,
  VISITOR_EVENTS,
} from "../../../../redux/api/notifications/types"

import AssetSVG from "../../../../assets/images/icons/Asset.svg"
import BadgeOutlineSVG from "../../../../assets/images/icons/BadgeOutline.svg"
import ChairSVG from "../../../../assets/images/icons/Chair.svg"
import SyncProblemOutlineSVG from "../../../../assets/images/icons/SyncProblem.svg"
import WarningOutlineSVG from "../../../../assets/images/icons/WarningOutline.svg"

/**
 * Once we start supporting more types, we include them here and also filter them in the NotificationList component (not implemented yet)
 */
export const SUPPORTED_NOTIFICATION_TYPES = [
  VISITOR_EVENTS.INVITE_CREATED_HOST,
  VISITOR_EVENTS.INVITE_UPDATED_HOST,
  VISITOR_EVENTS.INVITE_CHECKED_IN_HOST,
  VISITOR_EVENTS.INVITE_CANCELLED_HOST,

  DESK_EVENTS.RESERVATION_RELEASED,

  ASSET_EVENTS.RESERVATION_RELEASED,

  SYSTEM_EVENTS.PREPAID_CODE_TO_EXPIRE,
  SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC,
] as const

export const EVENT_ICON: {
  [key: string]: Children
} = {
  [VISITOR_EVENTS.INVITE_CREATED_HOST]: <BadgeOutlineSVG />,
  [VISITOR_EVENTS.INVITE_CHECKED_IN_HOST]: <BadgeOutlineSVG />,
  [VISITOR_EVENTS.INVITE_UPDATED_HOST]: <BadgeOutlineSVG />,
  [VISITOR_EVENTS.INVITE_CANCELLED_HOST]: <BadgeOutlineSVG />,

  [DESK_EVENTS.RESERVATION_RELEASED]: <ChairSVG />,

  [ASSET_EVENTS.RESERVATION_RELEASED]: <AssetSVG />,

  [SYSTEM_EVENTS.PREPAID_CODE_TO_EXPIRE]: <WarningOutlineSVG />,
  [SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC]: <SyncProblemOutlineSVG />,
}

export const EVENT_SENSITIVITY: {
  [key: string]: Sensitivity
} = {
  [VISITOR_EVENTS.INVITE_CREATED_HOST]: SensitivityOptions.NORMAL,
  [VISITOR_EVENTS.INVITE_CHECKED_IN_HOST]: SensitivityOptions.NORMAL,
  [VISITOR_EVENTS.INVITE_UPDATED_HOST]: SensitivityOptions.NORMAL,
  [VISITOR_EVENTS.INVITE_CANCELLED_HOST]: SensitivityOptions.NORMAL,

  [DESK_EVENTS.RESERVATION_RELEASED]: SensitivityOptions.NORMAL,

  [ASSET_EVENTS.RESERVATION_RELEASED]: SensitivityOptions.NORMAL,

  [SYSTEM_EVENTS.PREPAID_CODE_TO_EXPIRE]: SensitivityOptions.IMPORTANT,
  [SYSTEM_EVENTS.CALENDAR_OUT_OF_SYNC]: SensitivityOptions.IMPORTANT,
}
