import React from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { timeZone as defaultTimezone } from "../../dayjs"
import { userTimeFormat } from "../../utils"
import { IconMapper } from "../CorrectIconMapper"
import { EventActions } from "./EventActions"

import { formatFloorLabel } from "../../redux/floors/utils"

import AccountSVG from "../../assets/images/icons/Account.svg"
import ClockSVG from "../../assets/images/icons/Clock.svg"
import PinOutlineSVG from "../../assets/images/icons/PinOutline.svg"

import "./EventRow.sass"

type EventRowProps = {
  event: any
}

export const EventRow: React.FC<EventRowProps> = ({ event }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const today = dayjs()

  const start = dayjs(event.start)
  const end = dayjs(event.end)

  const isActive = today.isAfter(start) && today.isBefore(end)

  const eventRowClassName = classNames({
    EventRow: true,
    isActive,
  })

  const building = event.room.locations.find((l: any) => l.type === 0)
  const floor = event.room.locations.find((l: any) => l.type === 1)

  const attendees = []
  if (event.organizer && event.organizer.email !== event.room.email) {
    attendees.push(event.organizer.displayName)
  }
  attendees.push(
    ...event.attendees
      .filter(
        (a: any) =>
          !event.organizer ||
          (a.mail !== event.organizer && event.organizer.email),
      )
      .map((a: any) => a.name),
  )

  const location = [event.room.name]

  const timezoneDiffers = event.tz !== defaultTimezone

  /**
   * Remove duplicates from attendees as backend returns the booker email twice
   */
  const uniqueAttendees = Array.from(new Set(attendees))

  return (
    <div
      className={eventRowClassName}
      onClick={() => {
        history.push("/home/event/" + event.id)
      }}
    >
      <div className="resource-info">
        <div className="event-title">
          {event?.summary && event?.summary !== ""
            ? event.summary
            : t("mobile.home.upcoming_meeting")}
        </div>
        <div className="aux-info">
          <div className="resource-time">
            <ClockSVG className="resource-time-icon" />
            <span className="from">{start.format(userTimeFormat())}</span>
            <span className="dash">{"–"}</span>
            <span className="to">{end.format(userTimeFormat())}</span>
            {timezoneDiffers && event.tz && (
              <div className="diff-tz-container">
                <span> &middot; </span>
                <span className="diff-tz">{event.tz}</span>
              </div>
            )}
          </div>
        </div>
        <div className="building-floor">
          <PinOutlineSVG className="building-floor-icon" />
          <div className="building-floor-data">
            {location[0]}
            {building?.name && `, ${building?.name}`}
            {floor?.name &&
              `, ${formatFloorLabel(t("mobile.book.floor"), floor?.name)}`}
          </div>
        </div>
        {uniqueAttendees.length > 0 && (
          <div className="attendees">
            <AccountSVG className="account-icon" />
            {uniqueAttendees[0]}
            {uniqueAttendees.length > 1 && (
              <div className="attendees-others">
                {" + "}
                {uniqueAttendees.length - 1} {t("mobile.home.others")}
              </div>
            )}
          </div>
        )}
        {isActive && <EventActions event={event} small={true} />}
      </div>
      <IconMapper
        parentClassName="icon"
        color={isActive ? "white" : "default"}
        iconType="room"
      />
    </div>
  )
}
