import React from "react"

import { Redirect, Switch as RouteSwitch } from "react-router-dom"

import { ROOMS_PATHS } from "./constants"
import MeetingsPage from "./Meetings"
import PeoplePage from "./People"
import RoomsPage from "./Rooms"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Rooms = () => {
  return (
    <RouteSwitch>
      <ProtectedRoute
        exact
        path={ROOMS_PATHS.meetings}
        component={MeetingsPage}
      />
      {/*<ProtectedRoute exact path={ROOMS_PATHS.people} component={PeoplePage} />*/}
      {/*<ProtectedRoute exact path={ROOMS_PATHS.rooms} component={RoomsPage} />*/}

      <Redirect to={ROOMS_PATHS.meetings} />
    </RouteSwitch>
  )
}

export default Rooms
