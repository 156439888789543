import { useMemo, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useHistory } from "react-router-dom"

import { ROOM_BOOKING_PATHS } from "./constants"
import Header from "./Header"
import { roomReservationsMockData } from "./mock"

import { toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"
import { useActions } from "../../../redux/utils"

import Space from "../../../components/Space"
import View from "../../../components/View"

import CalendarViewDaySVG from "../../../assets/images/icons/CalendarViewDay.svg"
import CalendarViewWeekSVG from "../../../assets/images/icons/CalendarViewWeek.svg"

import "./style.sass"

export type CalendarView = "day" | "week"

const ManageRooms = () => {
  const history = useHistory()

  const { fromDate, toDate, showWeekends, currentDate } =
    useAppSelector(selectAppDates)

  const weekEnd = showWeekends ? toDate : toDate.subtract(2, "day")

  const actions = useActions({
    toggleWeekends: () => toggleWeekends(),
  })

  const manageRoomsClassName = classNames("ManageRooms", {
    showWeekends,
  })

  const [calendarView, setCalendarView] = useState<CalendarView>("week")

  /*TODO: Draft code for filtering reservation according to the selected calendar display method and selected
     date/range of dates. Will be changed later in the context of another ticket.*/
  const filteredReservationsMockData = useMemo(() => {
    if (calendarView === "day") {
      return roomReservationsMockData.filter((r) =>
        r.schedule.some(({ day }) => dayjs(day).isSame(currentDate, "day")),
      )
    }

    if (calendarView === "week") {
      return roomReservationsMockData.filter((r) =>
        r.schedule.some(({ day }) =>
          dayjs(day).isBetween(fromDate, weekEnd, "day", "[]"),
        ),
      )
    }

    return []
  }, [calendarView, currentDate, fromDate, weekEnd])

  const handleNewReservation = () => history.push(ROOM_BOOKING_PATHS.add)

  return (
    <View className={manageRoomsClassName}>
      <Header
        calendarView={calendarView}
        currentDate={currentDate}
        fromDate={fromDate}
        weekEnd={weekEnd}
        showWeekends={showWeekends}
        toggleWeekends={actions.toggleWeekends}
        onNewReservation={() => handleNewReservation()}
      />

      <Space size={0.75} />

      {/*TODO: Draft code for switching the way the calendar is displayed. Will be changed later in the context of another ticket.*/}
      <div style={{ display: "flex", gap: "1rem" }}>
        <CalendarViewDaySVG onClick={() => setCalendarView("day")} />

        <CalendarViewWeekSVG onClick={() => setCalendarView("week")} />
      </div>

      <Space size={0.75} />

      {/*TODO: Draft code for displaying reservation list. Will be added later in the context of another ticket.*/}
      <pre>{JSON.stringify(filteredReservationsMockData, null, 4)}</pre>
    </View>
  )
}

export default ManageRooms
