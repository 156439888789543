import { MouseEvent, useState } from "react"

import { useTranslation } from "react-i18next"

import { analyticsEvent, SupportedEvents } from "../../analytics"
import Button from "../advanced/Button"
import { ConfirmationModal } from "../advanced/ConfirmationModal"
import { DeleteTypeField } from "../Field/DeleteTypeField"
import { toast } from "../Toast"
import { useModals } from "@mattjennings/react-modal-stack"

import { useDeleteDeskReservationMutation } from "../../redux/api/deskReservations"
import { isRejected } from "../../redux/api/types"
import { deleteAssetReservation } from "../../redux/asset_reservation/assetReservationSlice"
import {
  ReservationActionType,
  ReservationType,
} from "../../redux/reservations/types"
import { useActions } from "../../redux/utils"

import CrossSVG from "../../assets/images/icons/Cross.svg"

import "./DeleteReservationForm.sass"

interface Props {
  reservationId: string
  reservationType: ReservationType
  dataUpdate?: () => void
}

export const DeleteReservationForm = ({
  reservationId,
  dataUpdate,
  reservationType,
}: Props) => {
  const { closeModal, closeAllModals, openModal } = useModals()
  const { t } = useTranslation()
  const [deleteOption, setDeleteOption] = useState<ReservationActionType>(
    ReservationActionType.CURRENT,
  )
  const [deleteDeskReservation] = useDeleteDeskReservationMutation()

  const actions = useActions({
    deleteDeskReservation: (id: string, type: ReservationActionType) =>
      deleteDeskReservation({ id, type }),
    deleteAssetReservation: (id: string, type: ReservationActionType) =>
      deleteAssetReservation({ reservationId: id, type: type }),
  })

  const onDeleteClick = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    let response
    if (reservationType === "desk") {
      response = await deleteDeskReservation({
        id: reservationId,
        type: deleteOption,
      })
    } else if (reservationType === "asset") {
      response = await actions.deleteAssetReservation(
        reservationId,
        deleteOption,
      )
    }
    if (response) {
      if (!isRejected(response)) {
        if (deleteOption === ReservationActionType.CURRENT) {
          toast.info(
            t("desktop.manage.desk_booking.form.reservation_deleted_toast"),
            { hideProgressBar: true },
          )
        } else {
          toast.info(
            t("desktop.manage.desk_booking.form.reservations_deleted_toast"),
            { hideProgressBar: true },
          )
        }
        analyticsEvent(SupportedEvents.RESERVATION_DELETE, {
          id: reservationId,
          type: deleteOption,
        })
        dataUpdate?.()
      } else {
        toast.error(response.error.message, { hideProgressBar: true })
      }
    }
  }

  const onDeleteConfirmation = (e: MouseEvent<HTMLElement>) => {
    openModal(ConfirmationModal, {
      onConfirm: async () => {
        await onDeleteClick(e)
        closeAllModals()
      },
    })
  }

  return (
    <div className="DeleteReservationForm ModalForm">
      <div className="title">
        <h1>{t("desktop.manage.desk_booking.form.delete_recurring")}</h1>
      </div>
      <div className="close" onClick={() => closeModal()}>
        <CrossSVG />
      </div>

      <div>
        <DeleteTypeField value={deleteOption} onChange={setDeleteOption} />
      </div>

      <div className="actions actions-delete-only">
        <Button
          variant="danger-pop"
          noConfirm
          className="delete"
          onClick={onDeleteConfirmation}
        >
          {t("general.delete")}
        </Button>
      </div>
    </div>
  )
}

export default DeleteReservationForm
