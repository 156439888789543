import i18n from "i18next"

import { API_ROOT, tokenURL } from "../../api"
import {
  ApiResponseError,
  getErrorCode,
  parseApiError,
} from "../../api/apiUtils"
import { formatErrorResponse } from "../../api/utils"
import { NON_RETRY_CODES } from "../../constants"
import type {
  FetchArgs,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/fetchBaseQuery"
import {
  BaseQueryFn,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react"

// URLs that should not have the `lang` query parameter appended due to security reasons OR other restrictions
const langExemptUrls = [tokenURL()]

/**
 * Appends the `lang` query parameter to a given URL.
 * @param {string} url - The original URL.
 * @param {string} lang - The language code to append.
 * @returns {string} - The updated URL with the `lang` parameter.
 */
export const appendLangToUrl = (url: string): string => {
  if (langExemptUrls.includes(url)) {
    return url
  }
  const lang = i18n.language.split("-")[0]
  const parsedUrl = new URL(url, API_ROOT)
  parsedUrl.searchParams.set("lang", lang)
  return parsedUrl.toString()
}

/**
 * Handles API requests and responses, with consistent treatment of certain HTTP status codes.
 *
 * The module fetchBaseQueryExtended exports a wrapper function which leverages Redux Toolkit
 * and the fetchBaseQuery function from '@reduxjs/toolkit/query/react' to provide a custom API fetching solution.
 * The use of async/await syntax results in more readable asynchronous code.
 *
 * @module
 * @param {string|FetchArgs} args - The arguments for your request. Either a string (the URL), or an object with properties.
 * @param {object} api - An API service.
 * @param {object} extraOptions - Extra options for the fetch function.
 *
 * @returns {{}} response - The server response.
 *
 * @throws {Error} If the server responds with an error, it is thrown.
 * The handling of specific status codes should be noted:
 *  401 response causes the authorization to be cleared,
 *  nonRetry codes defined in ../../fetch cause the request to fail immediately,
 *  402 prompts the function to attempt to interpret what feature is locked
 *  and change state to reflect this.
 *
 **/

export const fetchBaseQueryExtended = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    // Modify `args` to include the `lang` query parameter
    if (typeof args === "string") {
      args = appendLangToUrl(args)
    } else if (args.url) {
      args.url = appendLangToUrl(args.url)
    }

    const baseQuery = fetchBaseQuery({
      baseUrl: API_ROOT,
      prepareHeaders: (headers, { getState }) => {
        const {
          auth: { access_token },
        } = getState() as any
        if (access_token) {
          headers.set("authorization", `Bearer ${access_token}`)
        }
        return headers
      },
    }) as BaseQueryFn<any, unknown, ApiResponseError, {}, FetchBaseQueryMeta>

    const response = await baseQuery(args, api, extraOptions)

    // Handle successful response
    if (response.meta?.response?.ok) {
      return response
    }

    // Handle errors
    if (response.error) {
      const errorResponse: ApiResponseError = {
        data: response.error.data,
        status: response.meta?.response?.status ?? 0,
        formError: parseApiError(response.error.data),
        message: formatErrorResponse(response.error.data),
        errorCode: getErrorCode(response),
        url: typeof args === "string" ? args : args.url,
      }

      // Special case: 502 Bad Gateway due to test backend
      if (response.error.status === 502) {
        errorResponse.formError = { _error: "502 Bad Gateway" }
        errorResponse.message = "502 Bad Gateway"
        retry.fail(errorResponse)
      }

      if (
        typeof response.error.status === "number" &&
        NON_RETRY_CODES.includes(response.error?.status)
      ) {
        retry.fail(errorResponse)
      }
    }

    return response
  },
  { maxRetries: 0 },
)
