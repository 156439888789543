import { useCallback, useEffect, useMemo, useRef } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useHistory, useRouteMatch } from "react-router-dom"

import { ENTRIES_PER_PAGE_KEY } from "../../../constants"
import { timeZone } from "../../../dayjs"
import { useBackendPagination } from "../../../hooks/useBackendPagination"
import useCheckReservationWindowLength from "../../../hooks/useCheckReservationWindowLength"
import { useFetchPaginatedData } from "../../../hooks/useFetchPaginatedData"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useStoredFilter } from "../../../hooks/useStoredFilter"
import DeskBookingModal from "../../../modals/DeskBookingModal"
import { parseQueryWithDefault, updateHistory } from "../../../utils"
import {
  DESK_FILTERS_STORE_NAME,
  DESKS_SCHEDULE_PATHNAME,
  ENTRIES_PER_PAGE,
} from "../constants"
import { DeskRowHeading } from "./DeskRowHeading"
import DeskScheduleCell from "./DeskScheduleCell"
import DeskFilters, { Filter } from "./Filters"
import Header from "./Header"
import { useModals } from "@mattjennings/react-modal-stack"

import { toggleWeekends } from "../../../redux/app/appSlice"
import { selectAppDates } from "../../../redux/app/selectors"
import { fetchDesksSchedule } from "../../../redux/desk_schedule/deskScheduleSlice"
import { selectDeskSchedule } from "../../../redux/desk_schedule/selectors"
import {
  DeskScheduleReservation,
  ScheduleDeskDataRow,
} from "../../../redux/desk_schedule/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../redux/user/utils"
import { useActions } from "../../../redux/utils"

import Card from "../../../components/basic/Card"
import { FilterSpecialValues } from "../../../components/Filter/types"
import { createScheduleOption } from "../../../components/Form/options"
import { ReservationSummary } from "../../../components/Manage/ReservationSummary"
import ReservationTable, {
  ReservationTableRow,
  TableDataEntryDate,
} from "../../../components/Manage/ReservationTable"
import NoDataFound from "../../../components/NoDataFound"
import Pagination from "../../../components/Pagination"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./style.sass"

const { stringify } = queryString

type Params = {
  id?: string
}

type Props = {
  add?: boolean
}

const ManageDesks = ({ add }: Props) => {
  const { fromDate, toDate, showWeekends } = useAppSelector(selectAppDates)
  const startDate = useRef(fromDate.toISOString())
  const { entry: user } = useAppSelector(selectUser)
  const { offset, schedule, count, isLoading, isLoaded, error, aggregation } =
    useAppSelector(selectDeskSchedule)

  const desk_reservation_window_length = useCheckReservationWindowLength({
    globalSettings: true,
  })

  const defaultFilter: Filter = {
    department_id: FilterSpecialValues.ALL,
    building_id: user.building ? user.building.id : FilterSpecialValues.ALL,
    floor_id: FilterSpecialValues.ALL,
    amenity_id: [FilterSpecialValues.ALL],
    search: "",
    page: 1,
  }

  const [storedFilterValues, saveFilter] = useStoredFilter({
    filterName: DESK_FILTERS_STORE_NAME,
    defaultFilterValues: defaultFilter,
  })

  const reqParams = useRef<Filter>(storedFilterValues)
  /**
   * State
   */

  const isAdminManager = isOfficeManager(user) || isPortalAdmin(user)
  const lastDay = dayjs()
    .startOf("date")
    .add(desk_reservation_window_length ?? 7, "day")
  const weekEnd = showWeekends ? toDate : toDate.subtract(2, "day")
  const manageDesksClassName = classNames("ManageDesks", {
    showWeekends: !!showWeekends,
  })

  const { value: entriesPerPage, onChange: setEntriesPerPage } =
    useLocalStorage(ENTRIES_PER_PAGE_KEY, ENTRIES_PER_PAGE.toString())
  const entriesPerPageNum = parseInt(entriesPerPage)

  /**
   * Hooks
   */
  const {
    params: { id },
  } = useRouteMatch<Params>()
  const { openModal, closeAllModals } = useModals()
  const { t } = useTranslation()

  const actions = useActions({
    toggleWeekends: () => toggleWeekends(),
    fetchDesksSchedule: (
      filter: Filter & { offset?: number; start: string; end: string },
    ) => {
      const { page, ...params } = filter

      return fetchDesksSchedule({
        limit: entriesPerPageNum,
        ...params,
      })
    },
  })
  const history = useHistory()
  const { search, pathname } = history.location

  const { from, to, hasNext, hasPrevious, paginationLinks, calcRowNumber } =
    useBackendPagination({
      offset,
      totalNumberOfItems: count,
      entriesPerPage: entriesPerPageNum,
      maxLinks: 7,
      maxTrailingLinks: 2,
    })

  const { fetchDataPagination, setPage, page } = useFetchPaginatedData({
    reqParams: reqParams,
    path: DESKS_SCHEDULE_PATHNAME,
    fetchCall: actions.fetchDesksSchedule,
    fetchOptions: { start: fromDate.toISOString(), end: toDate.toISOString() },
    offset,
    limit: entriesPerPageNum,
  })

  /**
   * Function and memo
   */
  const handleFilterChange = useCallback(
    async (filter: Filter) => {
      reqParams.current = { ...filter, page: 1 }
      updateHistory(pathname, reqParams.current)
      fetchDataPagination()
    },
    [fetchDataPagination, pathname],
  )

  const handleModalClose = () => {
    fetchDataPagination()

    if (add) {
      history.push({
        pathname: DESKS_SCHEDULE_PATHNAME,
        search: stringify({
          search: reqParams.current.search,
        }),
      })

      return
    }

    history.push(DESKS_SCHEDULE_PATHNAME)
  }

  const tableData: ReservationTableRow<
    ScheduleDeskDataRow,
    DeskScheduleReservation & TableDataEntryDate
  >[] = useMemo(
    () =>
      schedule?.flatMap((d) => ({
        header: {
          id: d.id,
          name: d.name,
        },
        data: d.schedule.flatMap((s) =>
          s.reservations.map((r) => ({
            ...r,
            date: s.day,
          })),
        ),
      })),

    [schedule],
  )

  const addReservation = (params: Record<string, any> = {}) => {
    history.push({
      pathname: `${DESKS_SCHEDULE_PATHNAME}/add`,
      search: stringify(params),
    })
  }

  const editReservation = ({ id }: DeskScheduleReservation) => {
    history.push(`${DESKS_SCHEDULE_PATHNAME}/${id}`)
  }

  /**
   * Main routing logic for create and edit modal views.
   **/
  useEffect(() => {
    if (add) {
      const queryFormData = parseQueryWithDefault(search, {
        start: "",
        end: "",
        date: dayjs().toString(),
        schedule: "Once",
        desk: "",
        deskName: "",
        scheduleDate: dayjs()
          .add(desk_reservation_window_length ?? 7, "day")
          .toString(),
        tz: timeZone,
      })

      openModal(DeskBookingModal, {
        formData: {
          ...queryFormData,
          date: dayjs(queryFormData.date),
          schedule: createScheduleOption(
            queryFormData.schedule,
            queryFormData.date,
          ),
          scheduleDate: dayjs(queryFormData.scheduleDate),
        },
        onClose: handleModalClose,
      })
    } else if (id) {
      openModal(DeskBookingModal, {
        reservationId: id,
        onClose: handleModalClose,
      })
    } else {
      updateHistory(pathname, reqParams.current)
      fetchDataPagination()
      closeAllModals()
    }
  }, [add, id, search])

  // saves the current params into the locale store
  useEffect(
    () => () => {
      if (pathname.endsWith(DESKS_SCHEDULE_PATHNAME)) {
        saveFilter(reqParams.current)
      }
    },
    [saveFilter, pathname],
  )

  // it will navigate to the first page when there is no results on higher pages
  useEffect(() => {
    if (isLoaded && count > 0 && schedule.length === 0 && page > 1) {
      setPage(1)
    }
  }, [count, isLoaded, page, schedule.length, setPage])

  useEffect(() => {
    const start = fromDate.toISOString()

    if (startDate.current !== start) {
      fetchDataPagination()
      startDate.current = start
    }
  }, [fromDate])

  const isPaymentRequiredError = error && error.startsWith("Payment required")

  return (
    <View className={manageDesksClassName}>
      <Header
        fromDate={fromDate}
        toDate={toDate}
        weekEnd={weekEnd}
        showWeekends={showWeekends}
        showExportButton={isAdminManager}
        toggleWeekends={actions.toggleWeekends}
        onNewReservation={addReservation}
      />

      <Space size={0.75} />

      <DeskFilters
        onChange={handleFilterChange}
        defaultValues={storedFilterValues}
      />

      <Space size={0.75} />

      {error && !isPaymentRequiredError && (
        <>
          <Card className="small-font card-error">
            {t("desktop.manage.desk_booking.reservations_loading_error")}
          </Card>
          <Space size={0.75} />
        </>
      )}

      <ReservationTable
        showSummary
        isLoading={isLoading}
        weekStartDay={fromDate}
        entries={tableData}
        showWeekends={showWeekends}
        renderHead={({ row, index }) => (
          <DeskRowHeading
            deskRow={row}
            rowNumber={calcRowNumber(index)}
            key={`head-${index}`}
          />
        )}
        renderCell={({ data, day, row, rowIndex }) => (
          <DeskScheduleCell
            day={day}
            header={row}
            schedule={data}
            onClick={editReservation}
            rowIndex={rowIndex}
            renderVacancy={({ hasAvailableSlots, day }) => {
              const isBefore = dayjs(day).subtract(1, "day").isBefore(lastDay)
              return (
                <>
                  {hasAvailableSlots && (isBefore || isAdminManager) && (
                    <button
                      className="ReservationAvailable"
                      onClick={() => {
                        addReservation({
                          date: day,
                          desk: row.id,
                          deskName: row.name,
                          search: reqParams.current.search,
                        })
                      }}
                    >
                      {t("desktop.manage.desk_booking.available")}
                    </button>
                  )}
                </>
              )
            }}
          />
        )}
        renderSummary={({ day, index }) => (
          <ReservationSummary
            day={day}
            aggregation={aggregation}
            key={`tfoot-${index}`}
          />
        )}
        noResultsMessage={
          <NoDataFound>{t("general.not_found.no_desks_found")}</NoDataFound>
        }
        pagination={
          <Pagination
            links={paginationLinks}
            setPage={setPage}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            from={from}
            to={to}
            total={count}
            items={t("desktop.manage.desk_booking.desks")}
            entriesPerPage={entriesPerPageNum}
            setEntriesPerPage={setEntriesPerPage}
          />
        }
      />
    </View>
  )
}

export default ManageDesks
