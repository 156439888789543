import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { buildingsURL } from "../../../api"
import { useToast } from "../../../hooks/useToast"
import { ONBOARDING_PATHS } from "../constants"

import {
  setOnboardingDone,
  setOnboardingStep,
} from "../../../redux/app/appSlice"
import { selectOnboarding } from "../../../redux/app/selectors"
import { BuildingResponse } from "../../../redux/buildings/types"
import { useAppSelector } from "../../../redux/reducers"
import { selectUser } from "../../../redux/user/selectors"
import { fetchUser } from "../../../redux/user/userSlice"
import { UserRequest } from "../../../redux/users/types"
import { patchUser } from "../../../redux/users/usersSlice"
import { useActions } from "../../../redux/utils"

import AsyncSelect from "../../../components/advanced/AsyncSelect"
import Button from "../../../components/advanced/Button"
import Loader from "../../../components/basic/Loader"
import Field from "../../../components/Field"
import { setErrors } from "../../../components/Form/formUtils"
import PageForm from "../../../components/Form/PageFormHook"
import Heading from "../../../components/Heading"
import Intro from "../../../components/Intro"
import Space from "../../../components/Space"
import View from "../../../components/View"

import "./styles.sass"

type FormValues = {
  building: BuildingResponse
}

const BuildingSelect = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { errorToast, infoToast } = useToast()
  const onboarding = useAppSelector(selectOnboarding)

  const actions = useActions({
    fetchUser: () => fetchUser(),
    patchManagementUser: (email: string, payload: Partial<UserRequest>) =>
      patchUser({ email, payload }),
    setOnboardingStep: (step: number) => setOnboardingStep(step),
    setOnboardingDone: () => setOnboardingDone(),
  })

  const { entry: user, isLoading } = useAppSelector(selectUser)

  const hasPreviousStep = onboarding && onboarding.currentStep > 0

  const methods = useForm<FormValues>({
    defaultValues: { building: user.building ?? undefined },
  })

  const { control, setError } = methods

  const handleSetDefaultBuilding = async ({ building }: FormValues) => {
    if (!building?.id) {
      return errorToast(t("desktop.building_select.error"))
    }

    const response = await actions.patchManagementUser(user.email, {
      building_id: building.id,
    })

    if (patchUser.rejected.match(response)) {
      if (response.payload) {
        setErrors(response.payload, setError, errorToast)
      }

      return
    }

    await actions.fetchUser()
    infoToast(t("desktop.building_select.success"))
    actions.setOnboardingDone()
    history.push(onboarding?.url ?? "/")
  }

  const handleOnBack = () => {
    actions.setOnboardingStep((onboarding?.currentStep ?? 0) - 1)
  }

  return (
    <div className="BuildingSelect">
      {isLoading ? (
        <Loader />
      ) : (
        <View>
          <Heading>
            {t("desktop.building_select.default_building_required")}
          </Heading>

          <Space size={0.75} />

          <Intro>
            {t("desktop.building_select.before_start_select_building")}
          </Intro>

          <Space size={0.75} />

          <FormProvider {...methods}>
            <PageForm
              additionalButton={
                !hasPreviousStep ? (
                  <Button
                    variant="secondary-white"
                    onClick={() => history.push("/auth/logout")}
                  >
                    {t("desktop.settings.account.log_out_button")}
                  </Button>
                ) : undefined
              }
              updateMode={true}
              onUpdate={handleSetDefaultBuilding}
              backUrl={
                hasPreviousStep
                  ? ONBOARDING_PATHS[
                      onboarding.steps[onboarding.currentStep - 1]
                    ]
                  : undefined
              }
              onBack={handleOnBack}
            >
              <Field
                control={control}
                name="building"
                label={t("desktop.building_select.select_default_building")}
              >
                {(props) => (
                  <AsyncSelect
                    {...props}
                    urlGenerator={(fetchOptions) => {
                      return buildingsURL(fetchOptions)
                    }}
                    nothingFoundMessage={t(
                      "general.not_found.no_options_found",
                    )}
                    getOptionLabel={(building) => building.name}
                    getOptionValue={(building) => building.id}
                    isPaginated
                  />
                )}
              </Field>
            </PageForm>
          </FormProvider>
        </View>
      )}
    </div>
  )
}

export default BuildingSelect
