import { IdAndName } from "../../../../../types/sharedTypes"
import { getTzLocation } from "../utils"

import LocationPinOutlineSVG from "../../../../../assets/images/icons/LocationPinOutline.svg"

type Props = {
  building: IdAndName | null
  floor?: IdAndName | null
  tz: string | null
  message?: string
}

const Location = ({ building, floor, tz, message }: Props) => {
  const tzLocation = getTzLocation(tz)

  const locationParts = [
    tzLocation?.region,
    building?.name,
    floor?.name,
  ].filter(Boolean)

  return (
    <div className="description-row">
      <div className="description-row-icon">
        <LocationPinOutlineSVG />
      </div>

      <div className="description-row-text">
        {locationParts.join(", ")}
        {message && ` ${message}`}
      </div>
    </div>
  )
}

export default Location
