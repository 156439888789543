import React from "react"

import { useTranslation } from "react-i18next"

import Options from "../basic/Options"
import { Field } from "./Field"

import { ReservationActionType } from "../../redux/reservations/types"

import "./DeleteTypeField.sass"

type Props = {
  value: ReservationActionType
  onChange: (v: ReservationActionType) => void
}

/**
 * @deprecated
 */
export const DeleteTypeField = ({ value, onChange }: Props) => {
  const { t } = useTranslation()

  const deleteTypeOptions = [
    {
      value: ReservationActionType.CURRENT,
      label: t("general.reservation.this_reservation"),
    },
    {
      value: ReservationActionType.CURRENT_FUTURE,
      label: t("general.reservation.this_and_following_reservations"),
    },
  ]

  return (
    <Field className="DeleteTypeField">
      <Options
        options={deleteTypeOptions}
        value={value}
        onChange={(v: string) => onChange(v as ReservationActionType)}
      />
    </Field>
  )
}
