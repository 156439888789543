import dayjs from "dayjs"

import { api } from ".."
import { notificationsReadURL, notificationsURL } from "../../../api"
import { FETCH_WITH_NO_LIMIT } from "../../../constants"
import {
  MarkNotificationsAsReadRequest,
  NotificationDetailsResponse,
  NotificationsOptions,
  NotificationsResponse,
} from "./types"

import { SUPPORTED_NOTIFICATION_TYPES } from "../../../components/advanced/NotificationCard/NotificationCardGenerator/constants"

const FETCH_DEFAULTS = {
  limit: FETCH_WITH_NO_LIMIT,
  offset: 0,
}

export const notifications = api.injectEndpoints({
  endpoints: (builder) => ({
    fetchNotifications: builder.query<
      NotificationsResponse,
      NotificationsOptions | void
    >({
      query: (options) => {
        const { type = [], ...restOptions } = options || {}

        const cleanedType = type.map((t) => t.trim()).filter(Boolean)

        return {
          url: notificationsURL({
            ...FETCH_DEFAULTS,
            ...(cleanedType.length > 0
              ? {
                  type: cleanedType.join(","),
                }
              : {
                  type: SUPPORTED_NOTIFICATION_TYPES.join(","),
                }),
            ...restOptions,
          }),
        }
      },
      providesTags: (result, _error) =>
        result
          ? [
              ...result.results.map(({ id }) => ({
                type: "Notifications" as const,
                id,
              })),
              { type: "Notifications", id: "LIST" },
            ]
          : [{ type: "Notifications", id: "LIST" }],
    }),

    fetchNotificationsInfinite: builder.query<
      NotificationsResponse,
      NotificationsOptions | void
    >({
      query: (options) => {
        const { type = [], ...restOptions } = options || {}

        const cleanedType = type.map((t) => t.trim()).filter(Boolean)

        const fetchOptions = {
          ...FETCH_DEFAULTS,
          ...(cleanedType.length > 0
            ? { type: cleanedType.join(",") }
            : {
                type: SUPPORTED_NOTIFICATION_TYPES.join(","),
              }),
          ...restOptions,
          listId: undefined,
        }

        return {
          url: notificationsURL(fetchOptions),
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        if (queryArgs && queryArgs.listId) {
          return `${endpointName}-${queryArgs.listId}`
        }

        return endpointName
      },
      merge: (currentCache, newItems) => {
        return {
          ...newItems,
          deletedCount: 0,
          results: [...currentCache.results, ...newItems.results],
        }
      },
      transformResponse: (response: NotificationsResponse) => {
        return {
          ...response,
          deletedCount: 0,
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      providesTags: (_result, _error, args) =>
        args && args.listId
          ? [{ type: "Notifications", id: `INFINITE-${args.listId}` }]
          : [{ type: "Notifications", id: "INFINITE" }],
    }),

    fetchNotification: builder.query<
      NotificationDetailsResponse | undefined,
      string
    >({
      query: (_id) => ({
        url: notificationsURL({ ...FETCH_DEFAULTS }),
      }),
      providesTags: (_result, _error, id) => [{ type: "Notifications", id }],
      transformResponse: (
        response: NotificationsResponse,
        _meta,
        notificationId,
      ) => {
        return response.results.find(
          (notification) => notification.id === notificationId,
        )
      },
    }),

    markNotificationsAsRead: builder.mutation<
      void,
      MarkNotificationsAsReadRequest
    >({
      query: ({ ids }) => ({
        url: notificationsReadURL(),
        method: "PUT",
        body: {
          ids,
        },
      }),
      async onQueryStarted({ ids, listId }, { dispatch, queryFulfilled }) {
        const patchResults = dispatch(
          notifications.util.updateQueryData(
            "fetchNotificationsInfinite",
            { listId },
            (draft: NotificationsResponse) => {
              Object.assign(draft, {
                results: draft.results.map((notification) => {
                  if (ids.length === 0) {
                    notification.seen_at =
                      notification.seen_at ?? dayjs().toISOString()
                    return notification
                  }
                  if (ids.includes(notification.id)) {
                    notification.seen_at = dayjs().toISOString()
                  }
                  return notification
                }),
              })
            },
          ),
        )

        queryFulfilled.catch(patchResults.undo)
      },
      invalidatesTags: (_result, _error, args) =>
        args
          ? [
              ...args.ids.map((id) => ({
                type: "Notifications" as const,
                id,
              })),
              { type: "Notifications", id: "LIST" },
            ]
          : [{ type: "Notifications", id: "LIST" }],
    }),

    markNotificationsAsReadAndDiscard: builder.mutation<
      void,
      MarkNotificationsAsReadRequest
    >({
      query: ({ ids }) => ({
        url: notificationsReadURL(),
        method: "PUT",
        body: {
          ids,
        },
      }),
      async onQueryStarted({ ids, listId }, { dispatch, queryFulfilled }) {
        const patchResults = dispatch(
          notifications.util.updateQueryData(
            "fetchNotificationsInfinite",
            { listId },
            (draft: NotificationsResponse) => {
              Object.assign(draft, {
                results:
                  ids.length === 0
                    ? []
                    : draft.results.filter(
                        (notification) => !ids.includes(notification.id),
                      ),
                deletedCount:
                  ids.length === 0 ? 0 : draft.deletedCount + ids.length,
                count: ids.length === 0 ? 0 : draft.count - ids.length,
              })
            },
          ),
        )

        queryFulfilled.catch(patchResults.undo)
      },
      invalidatesTags: (_result, _error, args) =>
        args
          ? [
              ...args.ids.map((id) => ({
                type: "Notifications" as const,
                id,
              })),
              { type: "Notifications", id: "LIST" },
            ]
          : [{ type: "Notifications", id: "LIST" }],
    }),
  }),
})

export const {
  useFetchNotificationsQuery,
  useLazyFetchNotificationsInfiniteQuery,
  useFetchNotificationQuery,
  useMarkNotificationsAsReadMutation,
  useMarkNotificationsAsReadAndDiscardMutation,
} = notifications
